<template>
  <div class="ui-slider-arrow">
    <div class="ui-slider-arrow__img">
      <img src="@/assets/images/slider-arrow.png" alt="arrow">
    </div>
  </div>
</template>

<script>
export default {
  name: 'UISliderArrow',
};
</script>

<style lang="scss">
.ui-slider-arrow {
  font-size: 52px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-out;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
  &__img {
    width: 0.3em;
  }
}
</style>
