<template>
  <UIContainerShrunk class="life-quality">
    <div class="life-quality__title">
      The quality of life in Dubai will not disappoint you:
    </div>
    <div class="qualities">
      <div
        class="quality"
        v-for="quality in qualities"
        :key="quality.name"
      >
        <div class="quality__img">
          <img :src="quality.imgSrc" alt="quality-img">
        </div>
        <div class="quality__name" v-html="quality.name"></div>
      </div>
    </div>
    <div class="greetings">
      <div class="greetings__title">
        Welcome <span class="glue">to Dubai!</span>
      </div>
      <div class="greetings__text">
        There are real estate for any taste
      </div>
    </div>
  </UIContainerShrunk>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';

export default {
  name: 'LifeQualityBlockEN',
  components: { UIContainerShrunk },
  data: () => ({
    qualities: [
      {
        imgSrc: '/static/images/lifeQualities/1.png',
        name: 'Developed infrastructure',
      },
      {
        imgSrc: '/static/images/lifeQualities/2.png',
        name: 'Hospitable visa policy',
      },
      {
        imgSrc: '/static/images/lifeQualities/3.png',
        name: 'Zero criminality, including corruption',
      },
      {
        imgSrc: '/static/images/lifeQualities/4.png',
        name: 'Modern medicine',
      },
      {
        imgSrc: '/static/images/lifeQualities/5.png',
        name: 'Access to elite education',
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.life-quality {
  margin-top: 84px;
  margin-bottom: 60px;

  @include media-down($md) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 60px;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;

    @include media-down($md) {
      margin-bottom: 40px;
      font-size: 24px;
      line-height: 31px;
    }
  }

  .qualities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-down($lg) {
      justify-content: flex-start;
    }

    @include media-down($md) {
      justify-content: center;
    }

    .quality {
      margin-bottom: 24px;
      margin-right: 30px;
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-down($md) {
        margin-bottom: 40px;
        margin-right: 20px;
        width: 130px;
      }

      &:nth-child(2n) {
        @include media-down($md) {
          margin-right: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &__img {
        margin-bottom: 28px;
        width: 96px;
        @include media-down($md) {
          width: 80px;
          margin-bottom: 24px;
        }
      }
      &__name {
        text-align: center;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .greetings {
    margin-top: 58px;
    text-align: center;

    @include media-down($md) {
      margin-top: 5px;
    }

    &__title {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      text-transform: uppercase;
      color: #D4FF59;

      @include media-down($md) {
        margin-bottom: 26px;
      }
    }

    &__text {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;

      @include media-down($md) {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }
}
</style>
