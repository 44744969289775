<template>
  <transition name="fade" @click="closeModals">
    <div
      v-if="active"
      class="response"
      @click.self="closeModals"
    >
      <div class="response__content">
        <div class="response__cross" @click="closeModals">
          <img src="@/assets/images/cross.svg" alt="cross">
        </div>
        <div class="response__logo">
          <img src="@/assets/images/logo_white.svg" alt="logo">
        </div>

        <div class="response-info">
          <div class="response-info__success-img" v-if="responseModalInfo.success">
            <img src="@/assets/images/success.svg" alt="success">
          </div>
          <div class="response-info__text" v-html="responseModalInfo.text" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ResponseModal',
  computed: {
    ...mapGetters(['isModalActive', 'modalInfo']),
    active() {
      return this.isModalActive('response');
    },
    responseModalInfo() {
      return this.modalInfo('response');
    },
  },
  methods: {
    ...mapMutations(['closeModals']),
  },
};
</script>

<style scoped lang="scss">
.response {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-down($md) {
    background-color: transparent;
  }
  &__content {
    position: relative;
    max-height: 90%;
    height: 686px;
    width: 480px;
    padding: 40px 70px 200px 52px;
    overflow: auto;
    border-radius: 8px;
    background: #204A6C;
    display: flex;
    flex-direction: column;
    @include media-down($md) {
      width: 100%;
      height: 100%;
      max-height: none;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
      padding: 36px 45px 60px;
    }
  }
  &__logo {
    margin-bottom: 50px;
    width: 80px;
    align-self: center;
    @include media-down($md) {
      margin-bottom: 40px;
      width: 70px;
    }
  }
  &__cross {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 18px;
    transition: 0.4s ease-out;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  .response-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    &__success-img {
      width: 68px;
      @include media-down($md) {
        width: 55px;
      }
    }
    &__text {
      margin-top: 26px;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #D4FF59;
      @include media-down($md) {
        margin-top: 30px;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
</style>
