<template>
  <UIContainerShrunk class="closed-sales">
    <div class="closed-sales__title">
      Get access to private sales
    </div>
    <div class="closed-sales__text">
      Many projects are bought out before the official start of sales in a matter of days and hours
    </div>

    <div class="closed-sales__slider">
      <UISlider :items="examples" class="example">
        <template v-slot="{item}">
          <div class="example__img">
            <img :src="item.imgSrc" alt="example">
          </div>
          <div class="example__content">
            <div class="example__name" v-html="item.name"></div>
            <div class="example__text" v-html="item.text"></div>
            <div class="example__area" v-html="item.area"></div>
          </div>
        </template>
      </UISlider>
    </div>

  </UIContainerShrunk>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import UISlider from '@/components/UI/UISlider.vue';

export default {
  name: 'ClosedSalesEN',
  components: { UISlider, UIContainerShrunk },
  data: () => ({
    examples: [
      {
        imgSrc: '/static/images/closedSales/1.png',
        name: 'Nad AI Sheba Gardens',
        text: 'The first transaction took place after only 36 minutes, <span class="glue highlight">from $1.2 million.</span>',
        area: 'Nad Al Sheba district, Dubai',
      },
      {
        imgSrc: '/static/images/closedSales/2.png',
        name: 'Alaya Gardens',
        text: 'Sold out before the official start, <br>sold <span class="glue highlight">for $12.320 millions.</span>',
        area: 'Sport City district, Dubai',
      },
      {
        imgSrc: '/static/images/closedSales/3.png',
        name: 'ELIE SAAB Arabian Ranches',
        text: 'The villas were sold out in 29 minutes, starting <span class="glue highlight">from $1.266 million.</span>',
        area: 'Saheel district, Dubai',
      },
    ],
  }),
};
</script>

<style lang="scss">
.closed-sales {
  margin-top: 92px !important;

  &__title {
    margin-bottom: 17px;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    text-align: center;

    @include media-down($md) {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &__text {
    margin-bottom: 45px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  &__slider {
    @include media-down($md) {
      margin: 0 auto;
      width: 213px;
    }
  }
}

.examples {
  display: flex;
  justify-content: space-between;

  @include media-down($md) {
    width: 213px;
  }
}

.example {
  min-width: 32%;
  margin-right: 13px;
  background: #204A6C;
  border-radius: 8px;

  @include media-down($md) {
    width: 100%;
    min-width: 100%;
  }

  &__image {

  }

  &__content {
    padding: 27px;

    @include media-down($md) {
      padding: 15px 20px;
    }
  }

  &__name {
    margin-bottom: 9px;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;

    @include media-down($md) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__text {
    margin-bottom: 26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @include media-down($md) {
      font-size: 11px;
      line-height: 15px;
    }

    .highlight {
      font-weight: 500;
    }
  }

  &__area {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    @include media-down($md) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.ui-slider__control {
  &--left {
    left: -11px;
  }
  &--right {
    right: -11px;
  }
}
</style>
