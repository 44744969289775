<template>
  <button class="ui-button" :class="{'inactive': inactive}">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'UIButton',
  props: {
    inactive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.ui-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  background: #D4FF59;
  padding: 14px 40px;
  //transition: 0.2s box-shadow ease-out;
  @include media-down($md) {
    font-size: 14px;
    line-height: 18px;
  }
  &:hover {
    //opacity: 0.7;
    //box-shadow: 0px 0 6px 2px rgba(212, 255, 89, 0.5);
  }
  &:active {
    box-shadow: 0 0 6px 2px rgba(212, 255, 89, 0.5);
    transform: scale(0.95);
    filter:grayscale(20%);
    color: #22221c;
  }
}

.inactive {
  opacity: 0.7;
  filter:grayscale(100%);
  pointer-events: none;
  user-select: none;
}
</style>
