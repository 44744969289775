<template>
  <UIContainerShrunk class="market-numbers">
    <div class="market-numbers__title">
      Рынок недвижимости дубая в цифрах
    </div>
    <div class="market-numbers__visa">
      Покупая недвижимость в Объединенных Арабских Эмиратах вы инвестируете в стабильный рынок
      и сохраняете активы. При покупке недвижимости на сумму $205K инвестор получает визу
      резидента ОАЭ, а при покупке на $544,5К - Золотую Визу. Вы сможете находиться
      в стране без ограничений.
    </div>

    <div class="numbers">
      <div
        class="number"
        v-for="number in numbers"
        :key="number.imgSrc"
      >
        <div class="number__img-block">
          <div class="number__img">
            <img :src="number.imgSrc" alt="img">
          </div>
          <div class="number__info">
            <div class="number__count" v-html="number.count"></div>
            <div class="number__name" v-html="number.name"></div>
          </div>
        </div>
        <div class="number__volume">объем продаж</div>
        <div class="number__statistics-block">
          <div class="number__statistics-img">
            <img src="/static/images/marketNumbers/arrow.png" alt="arrow">
          </div>
          <div class="number__statistics-text">
            {{ number.statistics }}
          </div>
        </div>
        <div class="number__price">{{ number.price }}</div>
      </div>
    </div>

    <div class="market-numbers__addition">
      За 2022 год стоимость жилья в среднем ценовом сегменте увеличилась на 37%,
      премиум-класса более чем на 35%, а эконом класса на 15,7%.
    </div>
  </UIContainerShrunk>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';

export default {
  name: 'MarketNumbersBlockRU',
  components: { UIContainerShrunk },
  data: () => ({
    numbers: [
      {
        imgSrc: '/static/images/marketNumbers/1.png',
        count: '67,702',
        name: 'Апартаменты',
        statistics: '71,1% чем в 2021',
        price: '$35 474 млн.',
      },
      {
        imgSrc: '/static/images/marketNumbers/2.png',
        count: '22,609',
        name: 'Виллы',
        statistics: '42,1% чем в 2021',
        price: '$21 754 млн.',
      },
      {
        imgSrc: '/static/images/marketNumbers/3.png',
        count: '3,029',
        name: 'Коммерческие<br>помещения',
        statistics: '28,3% чем в 2021',
        price: '$1 307 млн.',
      },
      {
        imgSrc: '/static/images/marketNumbers/4.png',
        count: '3,912',
        name: 'Участки',
        statistics: '25,6% чем в 2021',
        price: '$13 585 млн.',
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.market-numbers {
  &__title {
    margin-bottom: 30px;
    max-width: 650px;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;

    @include media-down($md) {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__visa {
    margin-bottom: 50px;
    max-width: 650px;
    font-size: 14px;
    line-height: 20px;

    @include media-down($md) {
      margin-bottom: 46px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .numbers {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-down($md) {
      margin-bottom: 0;
    }

    .number {
      margin: 0 15px 50px;
      width: 192px;
      display: flex;
      flex-direction: column;

      @include media-down($md) {
        width: 100%;
        background: #204A6C;
        border-radius: 8px;
        align-items: center;
        padding: 30px 0;
        margin: 0 0px 36px;
      }

      &__img-block {
        display: flex;
        flex: 1;
      }
      &__img {
        margin-right: 9px;
        width: 51px;
        padding-top: 5px;

        @include media-down($md) {
          padding-top: 0;
          margin-right: 20px;
          width: 67px;
        }
      }
      &__info {
        margin-bottom: 35px;

        @include media-down($md) {
          margin-bottom: 40px;
        }
      }
      &__count {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;

        @include media-down($md) {
          margin-bottom: 9px;
          font-size: 32px;
          line-height: 34px;
        }
      }
      &__name {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }

      &__volume {
        padding-bottom: 5px;
        margin-bottom: 7px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);;
        font-size: 14px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.4);

        @include media-down($md) {
          margin-bottom: 12px;
          width: 100%;
          text-align: center;
        }
      }

      &__statistics-block {
        margin-bottom: 23px;
        display: flex;
        align-items: center;

        @include media-down($md) {
          margin-bottom: 16px;
        }
      }
      &__statistics-img {
        margin-right: 10px;
        width: 10px;
        @include media-down($md) {
          margin-right: 5px;
          width: 14px;
        }
      }
      &__statistics-text {
        font-size: 14px;
        line-height: 18px;
        color: #D4FF59;
      }

      &__price {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;

        @include media-down($md) {
          font-size: 32px;
          line-height: 42px;
        }
      }
    }
  }

  &__addition {
    max-width: 600px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
