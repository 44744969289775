<template>
  <InvestmentsEN
    v-if="isEnglish"
  />
  <InvestmentsRU
    v-else
  />
</template>

<script>
import InvestmentsRU from '@/components/views/investments/investments-block/InvestmentsRU.vue';
import InvestmentsEN from '@/components/views/investments/investments-block/InvestmentsEN.vue';
import { mapState } from 'vuex';

export default {
  name: 'InvestmentsView',
  components: {
    InvestmentsEN,
    InvestmentsRU,
  },
  computed: {
    ...mapState(['language']),
    isEnglish() {
      return this.language === 'en';
    },
  },
};
</script>

<style scoped>
</style>
