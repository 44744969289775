<template>
  <div class="footer">
    <UIContainerShrunk class="footer__container">
      <div class="footer__name">Krönung real estate</div>
      <div class="footer__address">
        Dubai, Business Bay, <br>The Binary by Omniyat, office 910
      </div>
    </UIContainerShrunk>
  </div>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';

export default {
  name: 'UIFooter',
  components: { UIContainerShrunk },
};
</script>

<style scoped lang="scss">
.footer {
  padding: 24px 0;
  @include media-down($lg) {
    padding: 20px 0;
    border-top: 2px solid #586773;
  }
  @include media-down($md) {
    padding: 26px 0;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-down($md) {
      flex-direction: column;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    @include media-down($lg) {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  &__address {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    @include media-down($lg) {
      font-size: 16px;
      line-height: 20px;
    }
    @include media-down($md) {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
