<template>
  <UIContainer class="objects">
    <div class="slider-container">
      <UISliderArrow
        class="slider-arrow"
        :class="{'hidden': this.sliderIndex === 0}"
        @click="swipe(this.sliderIndex - 1)"
      />
      <UISliderArrow
        class="slider-arrow"
        :class="{'hidden': this.sliderIndex === maxSliderIndex}"
        @click="swipe(this.sliderIndex + 1)"
      />

      <UIContainerShrunk class="slider">
        <div class="slider__content-container" ref="container">
          <div class="slider__content" ref="content">
            <div
              class="item"
              v-for="(item, index) in items"
              :class="{'last-visible': this.sliderIndex + 2 === index}"
              ref="item"
              :key="item.id"
              @click="selectItem(index)"
            >
              <div class="item__inner">
                <div class="item__img">
                  <img :src="item.preview.imgSrc" alt="photo">
                </div>
                <div class="item__content">
                  <div class="item__title">{{ item.title }}</div>
                  <div class="item__name">{{ item.name }}</div>
                  <div
                    class="item__price"
                    :class="{'item__price--en': isEnglish}"
                  >
                    {{ item.price }}
                  </div>
                  <div class="item__more">
                    <a :href="item.preview.moreURL" target="_blank">
                      {{ isEnglish ? 'learn more' : 'Подробнее' }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UIContainerShrunk>
    </div>

    <transition name="fade-fast" mode="out-in">
      <div class="about" :key="'animationToggle' + selectedItemIndex">
        <div class="about__images">
          <div class="about__image">
            <img :src="selectedItem.imgSrc1" alt="photo1">
          </div>
          <div class="about__image">
            <img :src="selectedItem.imgSrc2" alt="photo2">
          </div>
        </div>
        <UIContainerShrunk class="about__content">
          <div class="about__addition">
            <div
              class="about__price"
              :class="{'about__price--en': isEnglish}"
            >
              {{ selectedItem.price }}
            </div>
            <a class="about__more" target="_blank" href="https://t.me/KronungDubai">

              <span>
                <span v-html="isEnglish
                    ? 'More info in our'
                    : 'Больше информации<br>в нашем'"
                />
                <span class="highlight"> Telegram</span>
              </span>
              <img src="@/assets/images/tg_clear.svg" alt="tg">
            </a>
          </div>
          <div class="about__info">
            <div class="about__title">{{ selectedItem.title }}</div>
            <div class="about__name">{{ selectedItem.name }}</div>
            <div class="about__text">{{ selectedItem.text }}</div>
          </div>
        </UIContainerShrunk>
      </div>
    </transition>

  </UIContainer>
</template>

<script>
import UIContainer from '@/components/UI/UIContainer.vue';
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import UISliderArrow from '@/components/UI/UISliderArrow.vue';
import { gsap } from 'gsap';
import screenSizeInit from '@/mixins/screenSizeInit';

export default {
  name: 'ObjectsBlock',
  components: { UISliderArrow, UIContainerShrunk, UIContainer },
  props: {
    items: {
      type: Array,
      required: true,
    },
    language: {
      type: String,
      default: 'ru',
    },
  },
  data: () => ({
    sliderIndex: 0,
    itemWidth: null,
    itemsInView: null,
    selectedItemIndex: 0,
  }),
  mounted() {
    this.initSlider();
    window.addEventListener('resize', this.initSlider);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initSlider);
  },
  computed: {
    isEnglish() {
      return this.language.toLowerCase() === 'en';
    },
    maxSliderIndex() {
      return this.items.length - this.itemsInView;
    },
    selectedItem() {
      return this.items[this.selectedItemIndex];
    },
    isMobileScreen() {
      return this.screen.width <= this.screenWidthBreakPoints.md;
    },
  },
  methods: {
    initSlider() {
      this.sliderIndex = 0;
      if (!this.$refs.item) {
        this.timer = setTimeout(this.initSlider, 200);
        return;
      }
      this.itemWidth = gsap.getProperty(this.$refs.item[0], 'width');
      this.itemsInView = Math.floor(gsap.getProperty(this.$refs.container, 'width') / this.itemWidth);
      this.swipe(0);
    },
    swipe(index) {
      this.sliderIndex = index;
      this.sliderIndex = gsap.utils.clamp(0, this.maxSliderIndex, this.sliderIndex);

      gsap.to(this.$refs.content, {
        duration: 0.7,
        ease: 'power1.out',
        x: -this.sliderIndex * this.itemWidth,
      });

      if (!this.isMobileScreen) return;
      this.selectedItemIndex = this.sliderIndex;
    },
    selectItem(index) {
      if (this.isMobileScreen) return;
      this.selectedItemIndex = index;
    },
  },
  mixins: [screenSizeInit],
};
</script>

<style scoped lang="scss">
.objects {
  .slider-container {
    position: relative;
  }

  .slider-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;

    @include media-down($md) {
      font-size: 36px;
    }

    &:nth-child(1) {
      transform: translateY(-50%);

      @include media-down($md) {
        left: -10px;
      }
    }
    &:nth-child(2) {
      right: 0;
      transform: rotate(180deg) translateY(50%);

      @include media-down($md) {
        right: -10px;
      }
    }
  }
  .slider-arrow.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .slider {
    &__content-container {
      overflow: hidden;
      //padding: 30px 0;
      transition: 0.2s ease-out;

      padding: 30px 30px 30px 0;
      //width: calc(100% + 60px);
      width: calc(100% + 30px);

      @include media-down($md) {
        width: 100%;
        min-width: 213px;
        padding: 0;
      }
    }
    &__content {
      display: flex;
    }
  }

  .item {
    padding: 0 6px;
    min-width: 33.3%;
    cursor: pointer;
    transition: 0.2s ease-out;
    user-select: none;
    flex: 1;

    @include media-down($md) {
      min-width: 100%;
    }

    &:hover {
      transform: scale(1.1);
      min-width: 36.3%;
      padding: 0 22px;

      @include media-down($xl) {
        transform: scale(1.1);
        min-width: 35.3%;
        padding: 0 13px;
      }

      @include media-down($md) {
        transform: none;
        min-width: 100%;
        padding: 0 6px;
      }
    }

    &:first-child {
      //padding-left: 0;
    }

    &:last-child {
      //padding-right: 0;
    }

    &__inner {
      background: #FFFFFF;
      box-shadow: 0px 6px 8px rgba(8, 30, 51, 0.25);
      border-radius: 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__img {
    }

    &__content {
      color: #000;
      padding: 25px 30px;
      display: flex;
      flex-direction: column;
      flex: 1;

      @include media-down($xl) {
        padding: 12px;
      }

      @include media-down($md) {
        padding: 15px 20px 20px;
      }
    }

    &__title {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;

      @include media-down($xl) {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 14px;
      }

      @include media-down($md) {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__name {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      flex: 1;

      @include media-down($xl) {
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 12px;
      }

      @include media-down($md) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__price {
      margin-bottom: 18px;
      padding: 8px 0;
      color: #FFF;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      background: #204A6C;
      border-radius: 8px;
      width: 100%;

      @include media-down($xl) {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 18px;
        padding: 4px 0;
      }

      @include media-down($md) {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 23px;
      }
    }

    &__price--en {
      font-size: 28px;
      @include media-down($xl) {
        font-size: 20px;
      }
      @include media-down($md) {
        font-size: 18px;
      }
    }

    &__more {
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      text-transform: uppercase;
      color: #204A6C;

      @include media-down($xl) {
        font-size: 10px;
        line-height: 14px;
      }

      a {
        cursor: pointer;
        color: #204A6C;
        transition: 0.4s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .item.last-visible {
    margin-right: 30px;
  }

  .about {
    margin-top: 45px;

    &__images {
      display: flex;
      max-height: 469px;

      @include media-down($md) {
        min-width: 100vw;
        position: relative;
        left: -32px;
      }
    }

    &__image {
      max-height: 469px;
      img {
        //height: 100%;
        //width: auto;
      }
      &:nth-child(1) {
        margin-right: 13px;
      }
      &:nth-child(2) {
      }
    }

    &__content {
      margin-top: 44px;

      display: flex;
      align-items: flex-start;
      position: relative;

      @include media-down($lg) {
        padding: 0;
        justify-content: space-between;
        align-items: center;
      }

      @include media-down($md) {
        margin-top: 0;
        top: -20px;
        flex-direction: column;
        align-items: center;
      }
    }

    &__addition {
      margin-right: 146px;
      max-width: 280px;

      @include media-down($lg) {
        margin-right: 0;
        max-width: none;
      }
    }
    &__price {
      display: inline;
      padding: 12px 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: #112637;
      background: #D4FF59;
      border-radius: 8px;
    }
    &__price--en {
      font-size: 28px;
    }

    &__more {
      margin-top: 25px;

      display: block;
      cursor: pointer;
      transition: 0.4s ease-out;
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      text-decoration-line: underline;

      &:hover {
        opacity: 0.7;
      }

      .highlight {
        font-weight: 700;
      }
      img {
        margin-left: 10px;

        display: inline;
        max-width: 23px;
        width: 100%;
      }
    }

    &__info {
      position: absolute;
      right: 100px;
      bottom: 0;
      max-width: 490px;
      width: 100%;
      background: #204A6C;
      padding: 45px;

      @include media-down($xl) {
        padding: 20px;
        max-width: 400px;
        right: 70px;
      }

      @include media-down($lg) {
        margin-right: 40px;
        position: relative;
        right: auto;
        order: -1;
        flex: 1;
      }

      @include media-down($md) {
        margin-bottom: 40px;
        margin-right: 0;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 38px;

      @include media-down($xl) {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 30px;
      }

    }
    &__name {
      margin-bottom: 24px;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      @include media-down($xl) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
