<template>
  <div class="market-about">
    <UIContainerShrunk class="market-about__container">

      <div class="info">
        <div class="info__title">Dubai's real estate market</div>
        <div class="info__text">
          The UAE market has been growing in recent years, and now the indicators
          are breaking previous records. For example, the Dubai Land Department
          estimated a one-third increase in the cost of housing in Dubai in the
          first half of 2022 alone.
          <br><br>
          At the same time, despite the rapid growth, prices are still 30% behind the 2014
          peak. Investors from all over the world flocked
          <span class="glue"> to Dubai</span> because they saw the
          potential for business and reliable protection of their capital as well as personal
          safety, comfort and international status.
        </div>
        <div class="factors">
          <div class="factors__title">
            After moving to Dubai, we understood why it is one of the top five places in
            the world for expats. <br>Where else are such factors combined:
          </div>
          <div class="factors__content">
            <div
              class="factor"
              v-for="factor in factors"
              :key="factor"
            >
              <div class="factor__delimiter" />
              <div class="factor__text" v-html="factor"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="market-slider">
        <div class="market-slider__content">
          <DotsSlider :items="itemsImages" @swiped="onSwipe"/>
        </div>
        <transition name="fade-fast" mode="out-in">
          <div class="market-slider__name" :key="curItemName">
            {{ curItemName }}
          </div>
        </transition>
      </div>
    </UIContainerShrunk>
  </div>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import DotsSlider from '@/components/DotsSlider.vue';

export default {
  name: 'MarketAboutBlockEN',
  components: { DotsSlider, UIContainerShrunk },
  data: () => ({
    factors: [
      'Complete absence of taxes on personal income',
      'Interest-free installments with a down payment of 10%',
      'Long-term stable increase in real estate prices, <br><span class="highlight">and at the same time, <span class="glue">the growing</span> demand for it <span class="glue">for rent</span> and purchases</span>',
      'Mortgage for foreigners',
    ],
    items: [
      {
        name: 'Ellington Beach House',
        imgSrc: '/static/images/marketSlider/1.png',
      },
      {
        name: 'Hills Park',
        imgSrc: '/static/images/marketSlider/2.png',
      },
      {
        name: 'Marbella',
        imgSrc: '/static/images/marketSlider/3.png',
      },
      {
        name: 'Peninsula Four',
        imgSrc: '/static/images/marketSlider/4.png',
      },
      {
        name: 'Riviera',
        imgSrc: '/static/images/marketSlider/5.png',
      },
      {
        name: 'Creek Vista Heights',
        imgSrc: '/static/images/marketSlider/6.png',
      },
      {
        name: 'The Opus Residences',
        imgSrc: '/static/images/marketSlider/7.png',
      },
    ],
    curItemIndex: 0,
  }),
  computed: {
    itemsImages() {
      return this.items.map((item) => item.imgSrc);
    },
    curItemName() {
      return this.items[this.curItemIndex].name;
    },
  },
  methods: {
    onSwipe(index) {
      this.curItemIndex = index;
    },
  },
};
</script>

<style lang="scss">
.market-about {
  padding-bottom: 170px;
  padding-top: 90px;
  position: relative;
  background-image: url("~@assets/images/investments/market_about_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;

  @include media-down($lg) {
  }

  @include media-down($md) {
    padding-top: 48px;
    padding-bottom: 70px;
  }

  @include media-down($sm) {
    background-image: url("~@assets/images/investments/market_about_bg_mobile.png");
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @include media-down($lg) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .info {
    max-width: 462px;
    margin-right: 30px;

    @include media-down($lg) {
      margin-right: 0;
    }

    &__title {
      margin-bottom: 27px;
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;

      @include media-down($md) {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    &__text {
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      @include media-down($md) {
        margin-bottom: 23px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .factors {
      &__title {
        margin-bottom: 32px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;

        @include media-down($md) {
          font-size: 18px;
          line-height: 23px;
        }
      }

      &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .factor {
        margin-bottom: 35px;
        width: 225px;

        &__delimiter {
          margin-bottom: 14px;
          height: 3px;
          width: 50px;
          background-color: #D4FF59;
        }
        &__text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          .highlight {
            font-weight: 500;
          }
        }
      }
    }
  }

  .market-slider {
    padding-top: 50px;

    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-down($md) {
      padding-top: 15px;
      width: auto;
    }

    &__content {
      margin-bottom: 70px;
      position: relative;

      @include media-down($md) {
        margin-bottom: 40px;
      }
    }

    &__name {
      max-width: 225px;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: #D4FF59;
      min-height: 60px;
    }
  }
}
</style>
