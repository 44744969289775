<template>
  <div class="competition">
    <div class="competition__inner">
      <div class="competition__text">
      <span class="highlight">
        The influx of investors increases competition. Great deals close very quickly.
        In order to have time to track, check and formalize them, you need to keep your finger
        on the pulse. Know the language, local mentality, build long-term relationships with
        developers. That's exactly what we're doing.
      </span>
        <br><br>
        <span>
          A direct deal with the developer can be a good investment.
          <br>
          As a rule in Dubai, real estate of business / premium / elite classes really
          meets the stated standard. In public spaces there are swimming pools, gyms,
          coworking spaces, jacuzzi areas, playgrounds, barbecues and a full
          range <span class="glue">of other</span> infrastructure facilities.
        </span>
      </div>
      <div class="competition__img">
        <img src="@/assets/images/investments/competition.png" alt="competition">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitionBlockEN',
};
</script>

<style scoped lang="scss">
.competition {
  display: flex;
  justify-content: center;

  &__inner {
    margin: 0 16px;
    padding: 54px;
    max-width: 1087px;
    width: 100%;
    display: flex;
    background: #204A6C;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    top: -90px;
    overflow: hidden;

    @include media-down($md) {
      max-width: none;
      margin: 0 0 53px;
      padding: 42px 42px 180px;
      top: 0;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    max-width: 530px;

    @include media-down($md) {
      font-size: 14px;
      line-height: 18px;
    }

    .highlight {
      font-weight: 600;
    }
  }

  &__img {
    width: 532px;
    position: absolute;
    top: -70px;
    right: -80px;

    @include media-down($md) {
      width: 338px;
      transform: rotate(17deg);
      top: auto;
      bottom: -130px;
      right: auto;
      left: 20px;
    }
  }
}
</style>
