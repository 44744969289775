<template>
  <div
    class="ui-input"
    @click="focusInput"
    :class="{
      'shrunk': shrunk,
      'error': hasError,
    }"
  >
    <div ref="placeholder" class="ui-input__placeholder">
      {{ placeholder }}
    </div>
    <input
      type="text"
      ref="input"
      @blur="blurInput"
      @input="$emit('update:value', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'UIInput',
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    shrunk: false,
  }),
  beforeMount() {
    document.addEventListener('visibilitychange', this.blurInput, false);
    window.addEventListener('blur', this.blurInput, false);
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.blurInput, false);
    window.removeEventListener('blur', this.blurInput, false);
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
      this.shrunk = true;
    },
    blurInput() {
      this.$refs.input.blur();
      if (this.value) {
        this.shrunk = true;
        return;
      }
      this.shrunk = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ui-input {
  position: relative;
  border-bottom: 1px solid #DDDDDD;
  padding-bottom: 4px;
  transition: 0.4s ease-out;

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: 0.4s ease-out;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
  }

  input {
    border: none;
    background: transparent;
    outline: none;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    font-family: "Graphik LCG", sans-serif;
    width: 100%;
  }
}

.shrunk {
  .ui-input__placeholder {
    position: absolute;
    top: -6px;
    left: 0;
    font-size: 10px;
    line-height: 18px;
  }
}
.error {
  border-color: #f9623b;
}
</style>
