<template>
  <transition name="fade">
    <div
      v-if="active"
      class="comment"
      @click.self="closeModals"
    >
      <div class="comment__content">
        <div class="comment__cross" @click="closeModals">
          <img src="@/assets/images/cross.svg" alt="cross">
        </div>
        <div class="comment__logo">
          <img src="@/assets/images/logo_white.svg" alt="logo">
        </div>

        <div class="comment-form">
          <UIInput
            class="comment-form__input"
            placeholder="Имя*"
            v-model:value="form.name"
            :hasError="errors.name && highlightErrors"
          />
          <UIInput
            class="comment-form__input"
            placeholder="Фамилия*"
            v-model:value="form.secondName"
            :hasError="errors.secondName && highlightErrors"
          />
          <UIInput
            class="comment-form__input"
            placeholder="Почта*"
            v-model:value="form.email"
            :hasError="errors.email && highlightErrors"
          />
          <UIInput
            class="comment-form__input"
            placeholder="Телефон"
            v-model:value="form.phone"
          />
          <div class="comment-form__comments">
            <div class="comment-form__title">
              Ваш отзыв
            </div>
            <textarea
              v-model="form.comments"
              class="comment-form__comments-textarea"
              rows="4"
              :class="{'error': errors.comments && highlightErrors}"
            />
          </div>
          <div class="comment-form__agreement">
            <UICheckbox
              class="comment-form__checkbox"
              v-model:checked="form.agreement"
              :hasError="errors.agreement && highlightErrors"
            />
            <div class="comment-form__title">
              согласен с обработкой <span class="glue">персональных данных</span>
            </div>
          </div>
          <div class="comment-form__btn-container">
            <UIButton @click="send">Отправить</UIButton>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'lodash';
import validateEmail from '@/utils/validateEmail';
import UIInput from './UI/UIInput.vue';
import UIButton from './UI/UIButton.vue';
import UICheckbox from './UI/UICheckbox.vue';

export default {
  name: 'CommentModal',
  components: {
    UIInput,
    UIButton,
    UICheckbox,
  },
  data: () => ({
    errorsTimer: null,
    highlightErrors: false,
    form: {
      name: '',
      secondName: '',
      email: '',
      phone: '',
      comments: '',
      agreement: true,
    },
  }),
  computed: {
    ...mapGetters(['isModalActive']),
    errors() {
      const { form } = this;
      return {
        name: !form.name,
        secondName: !form.secondName,
        comments: !form.comments,
        email: !form.email || !validateEmail(form.email),
        agreement: !form.agreement,
      };
    },
    hasErrors() {
      return !!Object.values(this.errors).filter(Boolean).length;
    },
    active() {
      return this.isModalActive('comment');
    },
  },
  methods: {
    ...mapMutations(['closeModals', 'openModal']),
    ...mapActions(['sendComment']),
    // eslint-disable-next-line func-names
    send: debounce(function () {
      clearTimeout(this.errorsTimer);
      if (this.hasErrors) {
        this.highlightErrors = true;

        this.errorsTimer = setTimeout(() => {
          this.highlightErrors = false;
        }, 5000);
        return;
      }

      this.sendComment({
        name: this.form.name,
        secondName: this.form.secondName,
        email: this.form.email,
        comments: this.form.comments,
        phone: this.form.phone,
      });
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.comment {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-down($md) {
    background-color: transparent;
  }
  &__content {
    position: relative;
    max-height: 90%;
    width: 480px;
    padding: 40px 70px 52px;
    overflow: auto;
    border-radius: 8px;
    background: #204A6C;
    display: flex;
    flex-direction: column;
    @include media-down($md) {
      width: 100%;
      height: 100%;
      max-height: none;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
      padding: 36px 45px 60px;
    }
  }
  &__logo {
    margin-bottom: 50px;
    width: 80px;
    align-self: center;
    @include media-down($md) {
      margin-bottom: 40px;
      width: 70px;
    }
  }
  &__cross {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 18px;
    transition: 0.4s ease-out;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  .comment-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__title {
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      @include media-down($lg) {
        line-height: 22px;
      }
      @include media-down($md) {
        line-height: 17px;
      }
    }
    &__input {
      margin-bottom: 40px;
      width: 48%;
      &:first-child {
        flex: 1 1 100%;
      }
      &:nth-child(2) {
        flex: 1 1 100%;
      }
      @include media-down($md) {
        margin-bottom: 26px;
        flex: 1 1 100%;
      }
    }
    &__checkbox {
      margin-right: 8px;
    }
    &__comments {
      width: 100%;
    }
    &__comments-textarea {
      margin-top: 12px;
      border: 3px solid #D4FF59;
      outline: none;
      background: transparent;
      color: #FFFFFF;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      font-family: "Graphik LCG", sans-serif;
      transition: 0.4s ease-out;
      @include media-down($md) {
        margin-top: 4px;
      }
    }
    .error {
      border-color: #f9623b;
    }
    &__agreement {
      margin-top: 14px;
      margin-bottom: 52px;
      display: flex;
      align-items: center;
      @include media-down($md) {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    &__btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
