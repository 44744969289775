<template>
  <div class="welcome">

    <UIContainerShrunk class="hook">
      <div class="hook__title">Инвестируйте <span class="glue">в недвижимость</span> Дубая</div>
      <div class="hook__delimiter" />
      <div class="hook__text">Инвестируйте в растущий рынок недвижимости Дубая.
        <br>
        Сохраните свои средства и получите доходность до 40% в год.
      </div>
      <div class="hook__btn-container">
        <UIButton @click="openModal({name: 'contact'})">
          Свяжитесь с нами
        </UIButton>
      </div>
    </UIContainerShrunk>

    <div class="benefits">
      <div
        class="benefit"
        v-for="benefit in benefits"
        :key="benefit.text"
      >
        <div class="benefit__number">
          {{ benefit.numberPrefix }}
          <span class="highlight">{{ benefit.number }}</span>
        </div>
        <div class="benefit__delimiter" />
        <div class="benefit__text">{{ benefit.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'WelcomeBlockRU',
  components: { UIButton, UIContainerShrunk },
  data: () => ({
    benefits: [
      {
        numberPrefix: 'от',
        number: '8%',
        text: 'Доходность от сдачи в аренду',
      },
      {
        numberPrefix: 'до',
        number: '40%',
        text: 'Доходность при покупке строящегося объекта',
      },
      {
        numberPrefix: 'от',
        number: '10%',
        text: 'Первый взнос',
      },
    ],
  }),
  methods: {
    ...mapMutations(['openModal']),
    scrollToContacts() {
      document.getElementById('contacts').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    },
  },
};
</script>

<style scoped lang="scss">
.welcome {
  position: relative;
  height: calc(100vh - #{$headerHeight}px);
  min-height: 820px;
  background-image: url("~@assets/images/investments/welcome_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;

  @include media-down($md) {
    min-height: 890px;
    height: calc(100vh - #{$headerHeightMobile}px);
  }

  .hook {
    margin-bottom: 100px;
    padding-top: 80px;

    @include media-down($md) {
      margin-bottom: 52px;
      padding-top: 48px;
    }

    &__title {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 32px;
      line-height: 42px;
      text-transform: uppercase;

      @include media-down($md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__delimiter {
      margin-bottom: 20px;
      width: 78px;
      height: 3px;
      left: 231px;
      top: 222px;
      background: #D4FF59;

      @include media-down($md) {
        margin-bottom: 12px;
      }
    }

    &__text {
      margin-bottom: 45px;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;

      @include media-down($md) {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .benefits {
    max-width: 1087px;
    padding: 74px 56px 48px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background: rgba(17, 38, 55, 0.8);
    text-align: center;

    @include media-down($xl) {
      max-width: 800px;
      padding: 64px 46px 38px;
    }

    @include media-down($md) {
      max-width: 318px;
      flex-direction: column;
      align-items: center;
      padding: 36px 55px;
    }

    .benefit {
      width: 275px;

      @include media-down($xl) {
        width: 200px;
      }

      @include media-down($md) {
        width: 100%;
        margin-bottom: 38px;
      }

      &:last-child {
        @include media-down($md) {
          margin-bottom: 0;
        }
      }

      &__number {
        margin-bottom: 40px;
        text-align: center;
        font-weight: 500;
        font-size: 48px;
        line-height: 42px;

        @include media-down($xl) {
          margin-bottom: 30px;
          font-size: 40px;
          line-height: 40px;
        }

        @include media-down($md) {
          margin-bottom: 24px;
          font-size: 36px;
          line-height: 42px;
        }

        .highlight {
          font-size: 64px;
          line-height: 42px;
          color: #D4FF59;

          @include media-down($xl) {
            font-size: 46px;
            line-height: 42px;
          }

          @include media-down($md) {
            font-size: 48px;
            line-height: 42px;
          }
        }
      }

      &__delimiter {
        margin-bottom: 13px;
        height: 1px;
        background-color: #fff;
      }

      &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;

        @include media-down($xl) {
          font-size: 16px;
          line-height: 21px;
        }

        @include media-down($md) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
