<template>
  <div
    class="language"
    :class="{'active': isActive}"
    @click="setLanguage(language)"
  >
    {{ buttonName }}
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'UILanguageButton',
  props: {
    language: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      appLanguage: 'language',
    }),
    buttonName() {
      return this.language.toUpperCase();
    },
    isActive() {
      return this.language.toLowerCase() === this.appLanguage.toLowerCase();
    },
  },
  methods: {
    ...mapMutations(['setLanguage']),
  },
};
</script>

<style scoped lang="scss">
.language {
  cursor: pointer;
  color: rgba(145, 170, 192, 0.40);
  font-size: 14px;
  font-weight: 500;
  transition: all 0.4s;

  &:hover {
    color: #204A6C;
  }
}

.active {
  cursor: default;
  color: #204A6C;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;

  &:hover {
    color: #204A6C;
  }
}
</style>
