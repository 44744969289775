<template>
  <div class="real-estate" id="real-estate">
    <div class="about">
      <ScrollArrow class="about__arrow"/>
      <component :is="AboutContainer" class="about__container">
        <div class="about__content-container">
          <div class="about__content-bg"></div>
          <div class="about__content">
            <div class="about__title">
              GOODS AND DEVELOPERS
            </div>
            <div class="about__delimiter"></div>
            <div class="about__text">
              Direct access to developers is always important, particularly so in Dubai.
              The fact is that most of the best offers in the local real estate market are
              made literally "behind the scenes". Deals are sealed even before the official
              start of sales.
              <br><br>
              Besides that, interest-free installments and other types of indirect discounts
              are also available, mainly due to good personal relationships with
              representatives of construction and development companies. An important
              part of our work is to establish and maintain such links.
              <br><br>
              Currently, we know almost all notable players in the Dubai market not
              from catalogs, websites or presentations but personally. Saying it
              helps our customers would be an understatement.
            </div>
          </div>
        </div>
      </component>
    </div>

    <ObjectsBlock :items="items" language="en" />

    <ContactsBlock class="contacts"/>
  </div>
</template>

<script>
import navigationInit from '@/mixins/navigationInit';
import ContactsBlock from '@/components/ContactsBlock.vue';
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import ScrollArrow from '@/components/ScrollArrow.vue';
import ObjectsBlock from '@/components/objects/ObjectsBlock.vue';
import UIContainer from '@/components/UI/UIContainer.vue';
import screenSizeInit from '@/mixins/screenSizeInit';

export default {
  name: 'RealEstateEN',
  components: {
    UIContainer,
    ObjectsBlock,
    ScrollArrow,
    UIContainerShrunk,
    ContactsBlock,
  },
  data: () => ({
    headerBlocks: [
      'real-estate',
      'contacts',
    ],
    items: [
      {
        id: 'objectID-1',
        title: 'Ellington Beach House',
        name: 'Dubai, East Crescent, Palm Jumeirah',
        price: 'from $ 1 471 185',
        text: 'Ellington Beach House is the embodiment of a new lifestyle in a big city. The semicircular shape of the residential complex, reminiscent of a yacht facing the sea, is inspired by the trajectory of the sun, and allows you to watch the sunrise over the Persian Gulf and the sunset over the Dubai panorama every day.',
        imgSrc1: '/static/images/objects/content/1_1.png',
        imgSrc2: '/static/images/objects/content/1_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/1.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-2',
        title: 'Hills Park',
        name: 'Dubai Hills Estate',
        price: 'from $ 333 393',
        text: 'A new residential complex located in the leafy heart of Dubai, next to the Dubai Hills Park and golf club, only a few minutes away from the city center and other business, shopping and tourist centers. An island of nature in a bustling metropolis.',
        imgSrc1: '/static/images/objects/content/2_1.png',
        imgSrc2: '/static/images/objects/content/2_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/2.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-3',
        title: 'Marbella',
        name: 'Damac Lagoons',
        price: 'from $ 520 500',
        text: 'Marbella – townhouses inspired by the atmosphere of the eponymous Spanish resort town. Snow-white exteriors, panoramic windows, natural colors - everything is here to remind one of the Mediterranean atmosphere.',
        imgSrc1: '/static/images/objects/content/3_1.png',
        imgSrc2: '/static/images/objects/content/3_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/3.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-4',
        title: 'Peninsula Four, The Plaza',
        name: 'Dubai, Business Bay',
        price: 'from $ 266 575',
        text: 'A conceptually planned residential complex in one of the fanciest areas of Dubai. Two 52-floor towers with premium apartments. The architecture and location of the buildings of the project are carefully thought out and form the most convenient space for residents.',
        imgSrc1: '/static/images/objects/content/4_1.png',
        imgSrc2: '/static/images/objects/content/4_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/4.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-5',
        title: 'Rivera',
        name: 'Dubai, Meydan',
        price: 'from $ 268 767',
        text: 'The Riviera residential complex is a combination of Mediterranean design and modern architecture. Several mid-rise residential buildings are divided into studios and one, two and three-bedroom apartments. The complex is built in an area with a developed social and commercial infrastructure. The apartments offer stunning views on the lagoon and white sand beaches. Here you will be able to feel at home and become part of a diverse and multicultural community.',
        imgSrc1: '/static/images/objects/content/5_1.png',
        imgSrc2: '/static/images/objects/content/5_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/5.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-6',
        title: 'Creek Vista Heights',
        name: 'Sobha Hartland',
        price: 'from $ 345 205',
        text: 'Two high-rise towers located close to the bustling center of Dubai. A residential complex with high-class finishes and a unique atmosphere. Floor-to-ceiling windows offer magnificent views of the Burj Khalifa, Downtown Dubai and the Ras Al Khor Wildlife Sanctuary.',
        imgSrc1: '/static/images/objects/content/6_1.png',
        imgSrc2: '/static/images/objects/content/6_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/6.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-7',
        title: 'The Opus Residences',
        name: 'Dubai, Business Bay',
        price: 'from $ 1 124 292',
        text: 'The Opus Residences project is a new residential complex in the heart of Dubai, with fantastic views of the Burj Khalifa and the Arabian Gulf. Located close to the Dubai International Financial Centre and Dubai Mall, it is one of the latest works of the world-famous architect Zaha Hadid. Each apartment is a work of art, the interiors of the complex are furnished with furniture individually selected by the architect himself.',
        imgSrc1: '/static/images/objects/content/7_1.png',
        imgSrc2: '/static/images/objects/content/7_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/7.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-8',
        title: 'Vincitore Volare',
        name: 'Dubai, Golden Quarter',
        price: 'from $ 170 000',
        text: 'Vincitore Volare is the only developer that guarantees a net rental yield of 8% per annum after the completion of the project. The complex is designed in the style of Italian and French houses, architects and designers were inspired by Victorian architecture. The building is located in the lived-in area of Dubai, Arjan. Within walking distance from the house there are schools, educational institutions for children, shopping centers and clinics.',
        imgSrc1: '/static/images/objects/content/8_1.png',
        imgSrc2: '/static/images/objects/content/8_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/8.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
    ],
  }),
  computed: {
    AboutContainer() {
      return this.screen.width <= this.screenWidthBreakPoints.md
        ? UIContainer
        : UIContainerShrunk;
    },
  },
  mixins: [navigationInit, screenSizeInit],
};
</script>

<style scoped lang="scss" src="@/styles/views/realEstateView.scss">
</style>
