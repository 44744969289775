import { createStore } from 'vuex';
import axios from 'axios';
import { throttle } from 'lodash';

export default createStore({
  state: {
    modals: {
      slider: {
        src: '',
        active: false,
      },
      contact: {
        active: false,
      },
      comment: {
        active: false,
      },
      response: {
        active: false,
        success: true,
        text: 'Cпасибо',
      },
    },
    navigation: {
      curPage: '',
      headerBlocks: [],
      headerBlocksBreakPoints: [],
    },
    language: 'en',
  },
  getters: {
    isModalActive: (state) => (name) => state.modals[name].active,
    modalInfo: (state) => (name) => state.modals[name],
    curPage: (state) => state.navigation.curPage,
  },
  mutations: {
    closeModals(state) {
      Object.keys(state.modals)
        .forEach((key) => {
          state.modals[key].active = false;
        });

      document.documentElement.classList.remove('no-scroll');
    },
    openModal(state, { name, data }) {
      Object.keys(state.modals)
        .forEach((key) => {
          state.modals[key].active = false;
        });

      if (data) {
        state.modals[name] = {
          ...state.modals[name],
          ...data,
        };
      }
      state.modals[name].active = true;
      document.documentElement.classList.add('no-scroll');
    },
    swapCurPage(state, page) {
      state.navigation.curPage = page;
    },
    initBlocksCoords(state, headerBlocks) {
      state.navigation.headerBlocks = headerBlocks;

      const headerBlocksBreakPoints = [];

      headerBlocks.forEach((id) => {
        const el = document.getElementById(id);
        if (el) {
          headerBlocksBreakPoints.push(el.offsetTop - el.offsetHeight / 2);
        }
      });

      state.navigation.headerBlocksBreakPoints = [...headerBlocksBreakPoints];
    },
    setLanguage(state, language) {
      state.language = language.toLowerCase();
    },
  },
  actions: {
    onScroll: throttle(({ state, commit }) => {
      const offsetY = window.scrollY;
      state.navigation.headerBlocksBreakPoints.forEach((bp, index) => {
        if (offsetY < bp) return;
        commit('swapCurPage', state.navigation.headerBlocks[index]);
      });
    }, 200),
    // eslint-disable-next-line no-unused-vars
    async sendContact({ commit }, data) {
      // eslint-disable-next-line no-undef
      ym(90993598, 'reachGoal', 'formSubmit');
      try {
        await axios({
          method: 'POST',
          url: '/api/crm',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          data: JSON.stringify(data),
        });

        commit('openModal', {
          name: 'response',
          data: {
            success: true,
            text: 'Cпасибо',
          },
        });
      } catch (err) {
        console.log(err);
        commit('openModal', {
          name: 'response',
          data: {
            success: false,
            text: 'Что-то пошло не так...<br>Попробуйте ещё раз позже.',
          },
        });
      }
    },
    async sendComment({ commit }, data) {
      try {
        await axios({
          method: 'POST',
          url: '/api/comment',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          data: JSON.stringify(data),
        });

        commit('openModal', {
          name: 'response',
          data: {
            success: true,
            text: 'Cпасибо',
          },
        });
      } catch (err) {
        console.log(err);
        commit('openModal', {
          name: 'response',
          data: {
            success: false,
            text: 'Что-то пошло не так...<br>Попробуйте ещё раз позже.',
          },
        });
      }
    },
  },
});
