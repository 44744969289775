<template>
  <transition name="fade" @click="closeModals">
    <div class="slider-modal" v-if="sliderModalIsActive">
      <div class="slider-modal__content">
        <img :src="sliderModalInfo.src" alt="img">
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SliderModal',
  computed: {
    ...mapGetters(['isModalActive', 'modalInfo']),
    sliderModalIsActive() {
      return this.isModalActive('slider');
    },
    sliderModalInfo() {
      return this.modalInfo('slider');
    },
  },
  methods: {
    ...mapMutations(['closeModals']),
  },
};
</script>

<style scoped lang="scss">
.slider-modal {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    width: 100%;
    max-width: 70%;
    @include media-down($xl) {
      max-width: 80%;
    }
    @include media-down($lg) {
      max-width: 90%;
    }
  }
}
</style>
