<template>
  <div class="header">
    <UIContainer class="header__container">
      <transition name="fade">
        <div class="mobile-menu" v-if="menuIsActive">
          <div
            class="mobile-menu__item"
            v-for="page in pages"
            :key="'page' + page"
            :class="{'active': curPage === page.id}"
            @click="scrollToOrRedirect(page)"
          >
            {{ page.text }}
          </div>
        </div>
      </transition>

      <div class="header__logo" @click="scrollToOrRedirect(pages[0])">
        <img src="../../assets/images/logo.svg" alt="logo">
      </div>

      <div class="header__buttons-mobile">
        <div class="header__languages">
          <UILanguageButton
            language="ru"
            class="header__language"
          />
          <UILanguageButton
            language="en"
            class="header__language"
          />
        </div>

        <div
          class="header__hamburger hamburger"
          :class="{'is-active': menuIsActive}"
          @click="toggleMenu"
        >
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>

      <div class="header__buttons">
        <div class="header__links">
          <div
            v-for="page in pages"
            :key="'page' + page"
            class="header-link"
            :class="{'active': curPage === page.id}"
            @click="scrollToOrRedirect(page)"
          >
            <div class="header-link__text">
              {{ page.text }}
            </div>
            <div class="header-link__delimiter" />
          </div>
        </div>

        <div class="header__languages">
          <UILanguageButton
            language="en"
            class="header__language"
          />
          <UILanguageButton
            language="ru"
            class="header__language"
          />
        </div>
      </div>

    </UIContainer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import UILanguageButton from '@/components/UI/UILanguageButton.vue';
import UIContainer from './UIContainer.vue';

export default {
  name: 'UIHeader',
  components: {
    UILanguageButton,
    UIContainer,
  },
  computed: {
    ...mapState(['language']),
    ...mapGetters(['curPage']),
    isEnglish() {
      return this.language === 'en';
    },
    pages() {
      return this.isEnglish
        ? this.pagesEN
        : this.pagesRU;
    },
  },
  data: () => ({
    pagesRU: [
      {
        id: 'about',
        text: 'О нас',
        redirectPath: 'home',
      },
      {
        redirectPath: 'investments',
        id: 'investments',
        text: 'Инвестиции в Дубай',
      },
      {
        redirectPath: 'real-estate',
        id: 'real-estate',
        text: 'Объекты недвижимости',
      },
      {
        id: 'contacts',
        text: 'Контакты',
      },
    ],
    pagesEN: [
      {
        id: 'about',
        text: 'About us',
        redirectPath: 'home',
      },
      {
        redirectPath: 'investments',
        id: 'investments',
        text: 'Investment in Dubai',
      },
      {
        redirectPath: 'real-estate',
        id: 'real-estate',
        text: 'Real estate',
      },
      {
        id: 'contacts',
        text: 'Contacts',
      },
    ],
    menuIsActive: false,
  }),
  methods: {
    ...mapMutations(['swapCurPage']),
    scrollToOrRedirect(page) {
      if (page.id && document.getElementById(page.id)) {
        document.getElementById(page.id).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else {
        this.$router.push(page.redirectPath);
        this.scrollHome();
      }
      // for mobile
      this.menuIsActive = false;
    },
    scrollHome() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    toggleMenu() {
      this.menuIsActive = !this.menuIsActive;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  background-color: #fff;
  color: #000;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: #{$headerHeight}px;
    @include media-down($md) {
      height: #{$headerHeightMobile}px;
    }
  }
  .mobile-menu {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    width: 200px;
    @include media-down($md) {
      display: block;
    }
    .mobile-menu__item {
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      padding: 14px 23px;
      background-color: #fff;
      transition: 0.4s ease-out;
    }
    .mobile-menu__item.active {
      background: #D4FF59;
    }
  }
  &__logo {
    width: 72px;
    transition: 0.3s ease-out;
    cursor: pointer;
    @include media-down($md) {
      width: 48px !important;
    }
    &:hover {
      opacity: 0.6;
    }
  }

  &__buttons-mobile {
    display: none;

    @include media-down($md) {
      display: flex;
      align-items: center;
    }

    .header__languages {
      margin-right: 26px;
      .header__language {}
    }
  }

  &__hamburger {
    display: none;
    @include media-down($md) {
      display: block;
    }
  }

  &__links {
    margin-right: 70px;
    display: flex;
    @include media-down($md) {
      display: none;
    }
  }

  &__buttons {
    display: flex;

    @include media-down($md) {
      display: none;
    }
  }

  &__languages {
    display: flex;
  }

  &__language {
    &:first-child {
      margin-right: 14px;
    }
  }

  .header-link {
    margin-right: 40px;
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    color: #91AAC0;
    transition: 0.4s ease-out;
    position: relative;

    @include media-down($lg) {
      margin-right: 60px;
      font-size: 12px;
      line-height: 12px;
    }

    &:hover {
      color: #000000;
    }

    &:last-child {
      margin-right: 0;
    }

    &__text {
    }

    &__delimiter {
      height: 4px;
      width: 0;
      background-color: #C0FF0C;
      transition: 0.4s ease-out;
      bottom: -6px;
      position: absolute;
    }
  }
  .header-link.active {
    color: #000000;
    .header-link__delimiter {
      width: 100%;
    }
  }
}

// Hamburger
.hamburger .line{
  width: 26px;
  height: 2px;
  background-color: #000000;
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.hamburger:hover{
  cursor: pointer;
}

//.hamburger.is-active .line:nth-child(2){
//  opacity: 0;
//}

.hamburger.is-active .line:nth-child(1){
  transform: translateY(6px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(2){
  transform: translateY(-3px) rotate(-45deg);
}
</style>
