import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import RealEstateView from '@/views/RealEstateView.vue';
import InvestmentsView from '@/views/InvestmentsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/investments',
    name: 'investments',
    component: InvestmentsView,
  },
  {
    path: '/real-estate',
    name: 'real-estate',
    component: RealEstateView,
  },
  {
    path: '/crm',
    component: () => import('@/views/CRM.vue'),
  },
  { path: '/:pathMatch(.*)*', component: HomeView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
