<template>
<div class="scroll-arrow">
  <div class="scroll-arrow__content">
    <img src="@/assets/images/home/greetings/arrow.svg" alt="arrow">
  </div>
</div>
</template>

<script>
export default {
  name: 'ScrollArrow',
};
</script>

<style lang="scss">
.scroll-arrow {
  background: rgba(15, 31, 44, 0.6);
  backdrop-filter: blur(2px);
  border-radius: 64px;
  $size: 128px;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 94px;
  left: 50%;
  animation: 2s infinite alternate shake ease-in-out;

  @include media-down($md) {
    bottom: 110px;
    $size: 64px;
    width: $size;
    height: $size;
  }

  &__content {
    width: 57px;
    @include media-down($md) {
      width: 28px;
    }
  }
}

@keyframes shake {
  from {
    transform: translateY(-2px) translateX(-50%);
  }
  to {
    transform: translateY(5px) translateX(-50%);
  }
}
</style>
