<template>
<div class="comments-slider">
  <div class="comments-slider__container" ref="container">
    <div class="comments-slider__content" ref="content">
      <div
        class="comments-item"
        v-for="item in items"
        :key="item.name"
        ref="item"
      >
        <div class="comments-item__photo">
          <img :src="item.img" alt="photo">
        </div>
        <div class="comments-item__name">{{ item.name }}</div>
        <div class="comments-item__text-container">
          <div class="comments-item__text-content" ref="textContent">
            <div class="comments-item__text">{{ item.text }}</div>
          </div>
        </div>
        <transition name="fade">
          <div v-if="!item.scrolledDown" class="comments-item__border" ref="border" />
        </transition>
      </div>
    </div>

    <UISliderArrow
      class="comments-slider__control comments-slider__control--left"
      :class="{'hidden': this.curIndex === 0}"
      @click="swipe(this.curIndex - 1)"
    />
    <UISliderArrow
      class="comments-slider__control comments-slider__control--right"
      :class="{'hidden': this.curIndex === this.maxIndex}"
      @click="swipe(this.curIndex + 1)"
    />

  </div>
</div>
</template>

<script>
import { gsap } from 'gsap';
import { throttle } from 'lodash';
import UISliderArrow from '@/components/UI/UISliderArrow.vue';

export default {
  name: 'CommentsSliderEN',
  components: { UISliderArrow },
  data: () => ({
    items: [
      {
        img: '/static/images/comments/1.png',
        name: 'Renat Malikov',
        text: 'I turned to Krönung for help in choosing and buying an apartment in Dubai. Very quickly, several options were offered that fully satisfied my criteria (price, installments, location). I also received comprehensive advice on the residential real estate market. And in just two days, a contract of sale was signed with a reliable developer. The Krönung employees advise on how to pay bills more easily and safely and control the processes on the seller\'s side. I was most pleased with the work of the manager.',
      },
      {
        img: '/static/images/comments/2.png',
        name: 'Larisa Anisimova',
        text: 'I want to express my deep gratitude to Nadezhda Nikitenkova for her help in purchasing apartments in Dubai. Nadezhda very quickly and efficiently found apartment options, organized a video with the developer\'s presentation to view the options, taking my wishes into account. In just one day, an agreement was concluded with the developer, all via e-mail. There was no need to go to Dubai for registration, which made me significantly save time and money. Nadezhda worked out all the nuances personally, promptly resolved the issues of payment to the developer. Without a doubt, I could trust the developer to solve all the financial problems that arose, including depositing cash to pay on my behalf. Nadezhda is a true professional in her field, she\'s accurate and fast. All the issues were resolved instantly, she is truly responsible, always available and will answer any question, advise on how to do the right thing, dispel all doubts should there be any.',
      },
      {
        img: '/static/images/comments/3.png',
        name: 'Lida Bagirova',
        text: 'Krönung Real Estate rocks! We have been working together for 10 years, and I am sure this is only the beginning! They made my first trade in Dubai. I have never experienced such ease and confidence in large real estate. It was clear from the get go that the work was going to be done very professionally. That\'s exactly what happened. You can simply place an order and get everything turnkey, tied with a ribbon with a bow. This is no longer a contractor or a business partner, we are like friends would be!',
      },
      {
        img: '/static/images/comments/woman.png',
        name: 'Viktoria Koshkina',
        text: 'This was my first purchase of an apartment in Dubai, everything was done completely online. I was accompanied all along the way, from transferring funds from Russia to registration. The apartments were not on open sale at all, we looked at them online and did everything from sitting on our computers. I am grateful to the manager, Nadia, with whom I have been in touch all the time.',
      },
      {
        img: '/static/images/comments/man.png',
        name: 'Nikolai Bobrov',
        text: 'It was not possible for me to personally come to Dubai, open an account, and resolve legal issues. Krönung did everything for me online. Everything was worked out to perfection. Excellent professional work, highly recommend!',
      },
      {
        img: '/static/images/comments/man.png',
        name: 'Egor Petrov',
        text: 'We had an unusual real estate transaction. More precisely, we at first only had an idea, and the agency\'s employees helped a lot in order to formalize everything, to establish the project in the form of a specific action plan. We now own a network of commercial facilities.',
      },
      {
        img: '/static/images/comments/man.png',
        name: 'Nikolay Naumov',
        text: 'In 2021, we purchased our villa through the Krönung Real Estate agency. We had many requirements and almost all of them were met. As of September 2022, its value has increased by almost 40%. Judging by the trends in the Dubai market, this is not the limit. One of the most successful investments of my life, one of the easiest as well. Thank you!',
      },
    ],
    curIndex: 0,
    itemWidth: null,
    itemsInView: null,
    timer: null,
  }),
  computed: {
    maxIndex() {
      return this.items.length - this.itemsInView;
    },
  },
  mounted() {
    this.initSlider();
    this.hideItemsScrollBar();
    this.initItemsScrollCheck();
    window.addEventListener('resize', this.initSlider);
    window.addEventListener('resize', this.hideItemsScrollBar);
    window.addEventListener('resize', this.initItemsScrollCheck);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initSlider);
    window.removeEventListener('resize', this.hideItemsScrollBar);
    window.removeEventListener('resize', this.initItemsScrollCheck);
  },
  methods: {
    initSlider() {
      this.curIndex = 0;
      if (!this.$refs.item) {
        this.timer = setTimeout(this.initSlider, 200);
        return;
      }
      this.itemWidth = gsap.getProperty(this.$refs.item[0], 'width');
      this.itemsInView = Math.floor(gsap.getProperty(this.$refs.container, 'width') / this.itemWidth);

      this.itemWidth += gsap.getProperty(this.$refs.item[0], 'margin-right');
    },
    swipe(index) {
      this.curIndex = index;
      this.curIndex = gsap.utils.clamp(0, this.maxIndex, this.curIndex);

      gsap.to(this.$refs.content, {
        duration: 0.7,
        ease: 'power1.out',
        x: -this.curIndex * this.itemWidth,
      });
    },
    hideItemsScrollBar() {
      [].forEach.call(this.$refs.textContent, (item) => {
        // eslint-disable-next-line no-param-reassign
        item.style.paddingRight = `${item.offsetWidth - item.clientWidth + 2}px`;
      });
    },
    initItemsScrollCheck() {
      [].forEach.call(this.$refs.textContent, (item, i) => {
        this.checkItemScroll(i);

        this.$refs.textContent[i].addEventListener('scroll', throttle(this.checkItemScroll.bind(this, i), 200));
      });
    },
    checkItemScroll(itemIndex) {
      const el = this.$refs.textContent[itemIndex];

      this.items[itemIndex].scrolledDown = el.scrollHeight
        - Math.round(el.scrollTop) === el.clientHeight;
    },
  },
};
</script>

<style scoped lang="scss">
.comments-slider {
  position: relative;
  width: 100%;
  &__container {
    overflow: hidden;
  }
  &__content {
    display: flex;
    width: 100%;
    .comments-item {
      position: relative;
      width: 334px;
      padding: 38px 34px 20px;
      min-width: 32%;
      margin-right: 15px;
      background: #FFFFFF;
      box-shadow: 0 6px 8px rgba(8, 30, 51, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000000;
      @include media-down($lg) {
        min-width: 48%;
      }
      @include media-down($md) {
        padding: 30px 28px 24px;
      }
      @include media-down($sm) {
        min-width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
      &__photo {
        margin-bottom: 18px;
        width: 140px;
        border-radius: 50%;
        overflow: hidden;
        @include media-down($md) {
          margin-bottom: 14px;
          width: 114px;
        }
      }
      &__name {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        @include media-down($md) {
          margin-bottom: 10px;
        }
      }
      &__text-container {
        max-height: 200px;
        overflow: hidden;
        position: relative;
        @include media-down($md) {
          max-height: 170px;
        }
      }
      &__text-content {
        overflow: auto;
        height: 100%;
        width: 100%;
        box-sizing: content-box;
        position: relative;
      }
      &__text {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: start;
        @include media-down($md) {
          font-size: 12px;
          line-height: 17px;
        }
      }
      &__border {
        position: absolute;
        width: 100%;
        z-index: 2;
        bottom: 0;
        height: 42px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 43.23%);
        border-radius: 8px;
      }
    }
  }
  &__control {
    position: absolute;
    top: 50%;
    background: rgba(15, 31, 44, 0.6);

    @include media-down($xl) {
      font-size: 36px;
    }

    &--right {
      right: -50px;
      transform: rotate(180deg) translateY(50%) translateX(-100%);

      @include media-down($xl) {
        right: -12px;
      }

      @include media-down($md) {
        right: 30px;
      }

      @include media-down($sm) {
        right: 18px;
      }
    }
    &--left {
      left: -50px;
      transform: translateY(-50%) translateX(-100%);

      @include media-down($xl) {
        left: -12px;
      }

      @include media-down($md) {
        left: 22px;
      }

      @include media-down($sm) {
        left: 18px;
      }
    }
  }
  &__control.hidden {
    opacity: 0;
  }
  &__arrow {
    width: 29px;
    @include media-down($xl) {
      width: 20px;
    }
    @include media-down($sm) {
      width: 18px;
    }
  }
}
</style>
