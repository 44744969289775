<template>
  <div
    class="slider"
    :class="{'slider-shadow': shadow}"
  >
    <div
      class="slider__content"
      ref="content"
      :class="{'is-dragging': dragAndDrop.active}"
    >
      <template v-if="typeof items[0] === 'string'">
        <div
          v-for="src in items"
          :key="src"
          class="slider__item"
        >
          <img :src="src" alt="img" draggable="false">
        </div>
      </template>

      <template v-else>
        <div
          v-for="item in items"
          :key="item.imageURL"
          class="slider__item"
        >
          <div class="slider__item-content">
            <div class="slider__img">
              <img :src="item.imageURL" alt="img" draggable="false">
            </div>
            <div class="slider__name">{{ item.name }}</div>
            <div class="slider__position">{{ item.position }}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="slider__controls">
      <div
        class="slider__dot"
        v-for="(item, i) in items"
        :key="'dot' + item"
        :class="{active: i === this.curIndex}"
        @click="swipe(i)"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

let gsapAnimation = null;

export default {
  name: 'DotsSlider',
  props: {
    shadow: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
    },
    autoSwipeTimer: {
      type: Number,
      default: 4000,
    },
  },
  data: () => ({
    dragAndDrop: {
      active: false,
      startX: null,
      endX: null,
      startMouseX: null,
    },
    animation: null,
    itemWidth: null,
    curIndex: 0,
    interval: null,
  }),
  mounted() {
    this.initSlider();
    window.addEventListener('resize', this.initSlider);

    const { content } = this.$refs;

    content.addEventListener('mousedown', this.startMove);
    content.addEventListener('touchstart', this.startMove);

    content.addEventListener('mousemove', this.move);
    content.addEventListener('touchmove', this.move);

    content.addEventListener('mouseleave', this.endMove);
    content.addEventListener('mouseup', this.endMove);
    content.addEventListener('touchend', this.endMove);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.initSlider);
  },
  computed: {
    maxIndex() {
      return this.items.length - 1;
    },
    maxX() {
      return this.maxIndex * this.itemWidth;
    },
  },
  methods: {
    initSlider() {
      this.itemWidth = gsap.getProperty(this.$refs.content, 'width');
      if (this.itemWidth === 0) {
        setTimeout(() => this.initSlider(), 200);
        return;
      }
      this.initInterval();
    },
    startMove(e) {
      clearInterval(this.interval);

      if (gsapAnimation) {
        gsapAnimation?.pause();
        gsapAnimation?.kill();
      }
      this.dragAndDrop.active = true;
      this.dragAndDrop.startMouseX = e.pageX || e.touches[0].pageX;
      this.dragAndDrop.startX = parseInt(gsap.getProperty(this.$refs.content, 'x', 'px'), 10);
    },
    move(e) {
      if (!this.dragAndDrop.active) return;

      const endMouseX = e.pageX || e.touches[0].pageX;
      const dist = (endMouseX - this.dragAndDrop.startMouseX);
      let x = this.dragAndDrop.startX + dist;

      x = gsap.utils.clamp(-this.maxX, 0, x);
      this.dragAndDrop.endX = x;
      gsap.set(this.$refs.content, { x });
    },
    endMove() {
      this.dragAndDrop.active = false;
      this.snapSlider();
      this.initInterval();
    },
    snapSlider() {
      let index = Math.abs(this.dragAndDrop.endX / this.itemWidth);
      index = gsap.utils.snap(1, index);

      // const diff = this.dragAndDrop.endX - this.dragAndDrop.startX;
      // if (Math.abs(diff) < 30) {
      //   this.swipe(this.curIndex);
      //   return;
      // }
      //
      // const index = diff > 0
      //   ? this.curIndex - 1
      //   : this.curIndex + 1;
      this.swipe(index);
    },
    initInterval() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.swipe(this.curIndex + 1);
      }, this.autoSwipeTimer);
    },
    swipe(index) {
      this.initInterval();

      if (gsapAnimation) gsapAnimation?.pause();

      this.curIndex = index;
      if (index > this.maxIndex) this.curIndex = 0;

      if (!this.$refs.content) {
        clearInterval(this.interval);
        return;
      }
      gsapAnimation = gsap.to(this.$refs.content, {
        duration: 0.8,
        x: `-${this.curIndex * 100}%`,
        ease: 'power1.out',
      });

      this.$emit('swiped', this.curIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 100%;
  overflow: hidden;
  z-index: 10;
  @include media-down($md) {
    max-width: 400px;
  }
  @include media-down($sm) {
    max-width: 300px;
    background-size: initial;
  }
  &__content {
    user-drag: none;
    display: flex;
    cursor: pointer;
    user-select: none;
  }
  &__item {
    flex: 1 0 100%;
  }

  &__item-content {}
  &__img {
    margin-bottom: 30px;
    max-height: 289px;
    overflow: hidden;
  }
  &__name {
    margin-bottom: 5px;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
  }
  &__position {
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
  }

  &__controls {
    position: absolute;
    left: 50%;
    bottom: -28px;
    transform: translateX(-50%);
    display: flex;
  }
  &__dot {
    margin-right: 7px;
    margin-top: 28px;
    background: #97B3C7;
    border-radius: 50%;
    $size: 5px;
    width: $size;
    height: $size;
    transition: 0.4s ease-out;
    cursor: pointer;
    @include media-down($md) {
      margin-top: 24px;
      $size: 4px;
      width: $size;
      height: $size;
    }
    &:hover {
      background: #FFFFFF;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__dot.active {
    cursor: default;
    width: 23px;
    border-radius: 20px;
    @include media-down($md) {
      width: 18px;
    }
    &:hover {
      background: #97B3C7;
    }
  }
}
.is-dragging {
  cursor: grab;
}
.slider-shadow {
  box-shadow: 0 8px 20px rgba(11, 21, 46, 0.4);
}
</style>
