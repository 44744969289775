<template>
  <div class="home" id="about">
    <div class="greetings">
      <UIContainer class="greetings__content">
        <div class="greetings__title">
          Krönung <br> Real Estate
        </div>
        <div class="greetings__delimiter" />
        <div class="greetings__text">
          we are where you want to live
        </div>
      </UIContainer>
<!--      <div class="greetings__arrow">-->
<!--        <div class="greetings__arrow-content">-->
<!--          <img src="@/assets/images/home/greetings/arrow.svg" alt="arrow">-->
<!--        </div>-->
<!--      </div>-->
      <ScrollArrow class="greetings__arrow" />
    </div>

    <div class="about">
      <div class="about__content">
        <div class="about__title">О нас</div>
        <div class="about__logo">
          <img src="@/assets/images/home/about/logo.svg" alt="logo">
        </div>
        <div class="about__text">
          <span class="highlight">
            С 2004 года работаем на рынке жилой <span class="glue">и коммерческой</span>
          недвижимости России, Испании,
          Мексики и ОАЭ. В нашем портфолио строительные проекты по возведению жилых домов,
          магазинов, торговых центров, а также управление инвестициями в сфере недвижимости.
          Это формальная сторона деятельности агентства Krönung Real Estate.
          </span>
          <br><br>
          Несмотря на большой опыт, команда молодая — все от 30 до 40 лет.
          <span class="glue">Мы привыкли</span> действовать быстро, точно и системно.
          <br><br>
          Гибкий подход к проектам особенно пригодился в Дубае.
          <span class="glue">В настоящее</span> время этот рынок
          принимает очередную волну инвесторов со всей планеты, потому что Эмираты создали
          привлекательные
          <span class="glue">и комфортные</span> условия для международного бизнеса и экспатов.
          <br><br>
          На рынке регулярно появляются выгодные предложения,
          <span class="glue">но они</span> не задерживаются надолго.
          <span class="glue">Нужно выстраивать</span> отношения
          <span class="glue">с застройщиками,</span> банками,
          государственными организациями,
          успевать раньше других и оформлять все <span class="glue">без ошибок.</span>
          А это как раз наша специализация.
        </div>
      </div>
      <AboutSlider />
    </div>

    <div class="numbers">
      <UIContainerShrunk class="numbers__container">
        <div class="numbers__title">
          Krönung Real Estate в цифрах
        </div>
        <div class="numbers__logo">
          <img src="@/assets/images/home/numbers/logo.svg" alt="logo">
        </div>
        <NumbersSliderRU />
      </UIContainerShrunk>
      <div class="numbers__corner">
        <img src="@/assets/images/top_bg_corner.png" alt="corner">
      </div>
    </div>

    <div class="reasons">
      <UIContainerShrunk class="reasons__container">
        <div class="reasons__title">
          Причины работать <span class="glue">с нами</span>
        </div>
        <div class="reasons__list" ref="reasonsList">
          <div
            class="reason"
            ref="reason"
            v-for="(reason, i) in reasons"
            :key="reason + i"
          >
            <div class="reason__img">
              <img alt="reason" :src="reason.img">
            </div>
            <div class="reason__text" v-html="reason.text" />
          </div>
        </div>
        <div class="reasons__btn-container" ref="reasonsBtn">
          <UIButton class="reasons__btn" @click="openModal({name: 'contact'})">
            Свяжитесь с нами
          </UIButton>
        </div>
      </UIContainerShrunk>
      <div class="reasons__bg">
        <img src="@/assets/images/home/services/bg.png" alt="bg">
      </div>
      <div class="reasons__bg-mobile">
        <img src="@/assets/images/home/services/bg_mobile.png" alt="bg">
      </div>
    </div>

    <div class="services">
      <UIContainerShrunk class="services__container">
        <div class="services__about">
          <div class="services__about-title">
            Услуги
          </div>
          <div class="services__about-text">
            На результаты любого бизнеса в Дубае можно рассчитывать исключительно при
            налаживании связей и эффективном личном общении. Поэтому первое и главное, что мы
            готовы предложить — представительство ваших интересов на месте.
            <br><br>
            Здесь важно заметить, что по законодательству Дубая невозможно приобрести недвижимость
            на любом этапе готовности без участия агентов. Такие правила. Комиссию платит продавец.
            Вопрос не в том, будете ли вы пользоваться услугами агентства, а в том — кому именно
            доверите ведение своего проекта.
          </div>
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services__estate-container">
        <div class="services__estate">
          <div class="services__estate-title">
            Услуги по сделкам <span class="glue">с недвижимостью</span>
          </div>
          <div class="services__estate-items">
            <div
              class="estate-item"
              v-for="item in estateItems"
              :key="item"
            >
              <div class="estate-item__delimiter" />
              <div class="estate-item__text" v-html="item" />
            </div>
          </div>
        </div>
        <div class="services__estate-corner">
          <img src="@/assets/images/bottom_bg_corner.png" alt="corner">
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services-more__container">
        <div class="services-more__content">
          <div class="services-more">
            <div class="services-more__finance">
              <div class="services-more__title">
                Финансовые услуги
              </div>
              <div class="services-more__items">
                <div
                  class="services-more__item"
                  v-for="item in servicesFinance"
                  :key="item"
                  v-html="item"
                />
              </div>
            </div>
            <div class="services-more__legal">
              <div class="services-more__title">
                Юридические услуги
              </div>
              <div class="services-more__items">
                <div
                  class="services-more__item"
                  v-for="item in servicesLegal"
                  :key="item"
                  v-html="item"
                />
              </div>
            </div>
          </div>
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services-tasks__container">
        <div class="services-tasks__corner">
          <img src="@/assets/images/top_bg_corner.png" alt="corner">
        </div>
        <div class="services-tasks">
          <div class="tasks-persons">
            <ServicesSlider />
          </div>

          <div class="tasks-info">
            <div class="tasks-info__content">
              <div class="tasks-info__text">
                Расскажите о своих задачах,
                <span class="glue">и мы</span>
                предложим оптимальное решение
              </div>
              <div class="tasks-info__btn-container">
                <a class="link" href="https://wa.me/79817394249" target="_blank">
                  <UIButton class="tasks-info__btn">
                    Свяжитесь с нами
                  </UIButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </UIContainerShrunk>

      <div class="services__bg-corner">
        <img src="@/assets/images/bottom_bg_corner.png" alt="corner">
      </div>
    </div>

    <div class="comments">
      <UIContainerShrunk class="comments__container">
        <div class="comments__title">Отзывы</div>
        <div class="comments__logo">
          <img src="@/assets/images/home/comments/logo.svg" alt="logo">
        </div>

        <CommentsSliderRU />

        <div class="comments__question">
          Хотите оставить отзыв о нашей работе?
        </div>
        <div class="comments__btn-container">
          <a class="link" href="https://wa.me/79817394249" target="_blank">
            <UIButton
              class="comments__btn"
            >
              Свяжитесь с нами
            </UIButton>
          </a>
        </div>
      </UIContainerShrunk>
    </div>

    <div class="instagram-widget">
      <div class="elfsight-app-a86b38a9-c3ed-4c46-80e9-8002a20a9755"></div>
    </div>

    <ContactsBlock class="home__contacts" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import validateEmail from '@/utils/validateEmail';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ContactsBlock from '@/components/ContactsBlock.vue';
import navigationInit from '@/mixins/navigationInit';
import ScrollArrow from '@/components/ScrollArrow.vue';
import ServicesSlider from '@/components/views/home/services/ServicesSlider.vue';
import UIContainer from '@/components/UI/UIContainer.vue';
import UIButton from '@/components/UI/UIButton.vue';
import AboutSlider from '@/components/views/home/about/AboutSlider.vue';
import NumbersSliderRU from '@/components/views/home/numbers/NumbersSliderRU.vue';
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import CommentsSliderRU from '@/components/views/home/comments/CommentsSliderRU.vue';

gsap.registerPlugin(ScrollTrigger);
let tl;

export default {
  name: 'HomeRU',
  data: () => ({
    errorsTimer: null,
    reasons: [
      {
        img: '/static/images/reasons/1_updated.svg',
        text: 'Говорим по-русски для вашего удобства. А еще по арабски и английски, как местные',
      },
      {
        img: '/static/images/reasons/2.svg',
        text: 'Знаем специфику Дубая, <span class="glue">находимся в Дубае</span>',
      },
      {
        img: '/static/images/reasons/3.svg',
        text: 'Проводим сделки под ключ, <span class="glue">от встречи</span> в аэропорту <span class="glue">до регистрации</span> права собственности',
      },
      {
        img: '/static/images/reasons/4.svg',
        text: 'Дорожим своей репутацией,<br> с нами работают десятилетиями',
      },
      {
        img: '/static/images/reasons/5.svg',
        text: 'Предлагаем все известные <span class="glue">объекты в сегменте</span> бизнес /<br> премиум / элит',
      },
      {
        img: '/static/images/reasons/6.svg',
        text: 'Имеем доступ к закрытым<br> (не публичным) предложениям <span class="glue">от застройщиков</span>',
      },
      {
        img: '/static/images/reasons/7.svg',
        text: 'Умеем и практикуем все онлайн. Некоторые наши клиенты ни разу <span class="glue">не были</span> в Дубае',
      },
    ],
    estateItems: [
      'Подбираем выгодные объекты с учетом требований и пожеланий',
      'Презентуем объектов онлайн или в офисе (Дубай, Санкт-Петербург)',
      'Сопровождаем и показываем объекты в Дубае с сервисом бизнес-класса',
      'Даем доступ к «закрытым» объектам, до официального старта продаж',
      'Подбираем управляющую компанию для сдачи недвижимости в аренду',
    ],
    servicesFinance: [
      'Делимся объективной информацией о полной цене владения',
      'Консультируем по вопросам, связанным с оплатой из России',
      'Подаем заявки и оформляем нерезидентам ипотеку в ОАЭ',
      'Получаем все возможные (иногда чуть больше) скидки у застройщиков, в том числе оформляем беспроцентную рассрочку',
    ],
    servicesLegal: [
      'Открываем счета в банке для нерезидентов ОАЭ',
      'Создаем местное юрлицо для управления недвижимостью',
      'Помогаем получить резидентскую визу (внж)',
      'Ассистируем при релокации родственников клиента',
      'Проводим юридическое сопровождение сделок с недвижимостью',
    ],
    headerBlocks: [
      'about',
      'contacts',
    ],
    reasonItemsInRow: null,
  }),
  components: {
    ServicesSlider,
    ScrollArrow,
    ContactsBlock,
    CommentsSliderRU,
    UIContainerShrunk,
    NumbersSliderRU,
    AboutSlider,
    UIContainer,
    UIButton,
  },
  // mounted() {
  //   this.initBlocksCoords();
  //   this.initScrollAnimations();
  //
  //   window.addEventListener('scroll', this.onScroll);
  //   window.addEventListener('resize', this.initScrollAnimations);
  //   window.addEventListener('resize', this.initBlocksCoords);
  //
  //   this.onScroll();
  // },
  mounted() {
    this.initScrollAnimations();
  },
  // beforeUnmount() {
  //   window.removeEventListener('scroll', this.onScroll);
  //   window.removeEventListener('resize', this.initScrollAnimations);
  //   window.removeEventListener('resize', this.initBlocksCoords);
  // },
  beforeUnmount() {
    window.removeEventListener('resize', this.initScrollAnimations);
  },
  computed: {
    errors() {
      const { form } = this;
      return {
        name: !form.name,
        email: !form.email || !validateEmail(form.email),
        agreement: !form.agreement,
      };
    },
    hasErrors() {
      return !!Object.values(this.errors).filter(Boolean).length;
    },
  },
  methods: {
    ...mapMutations(['openModal']),
    initScrollAnimations() {
      this.initReasonsAnimation();
    },
    initReasonsAnimation() {
      if (tl) {
        tl.clear();
        tl.kill();
      }
      // if (ScrollTrigger.getById('reasons')) ScrollTrigger.getById('reasons').kill();

      const { reason, reasonsList, reasonsBtn } = this.$refs;

      const containerWidth = gsap.getProperty(reasonsList, 'width');
      const reasonWidth = gsap.getProperty(reason[0], 'width');
      this.reasonItemsInRow = Math.floor(containerWidth / reasonWidth);

      // change scroll animation logic on mobile (animate only once)
      let toggleActions = 'restart pause resume reset';
      const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (viewWidth <= 768) {
        toggleActions = 'play pause resume pause';
      }

      tl = gsap.timeline({
        scrollTrigger: {
          id: 'reasons',
          trigger: reasonsList,
          start: '30% bottom',
          toggleActions,
        },
      });

      const tempReason = [].concat(reason);
      // eslint-disable-next-line no-plusplus
      while (tempReason.length) {
        const slice = [];

        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < this.reasonItemsInRow; k++) {
          slice.push(tempReason.shift());
        }

        tl
          .fromTo(slice, {
            opacity: 0,
            y: 10,
          }, {
            duration: 0.8,
            opacity: 1,
            y: 0,
          }, '>-0.4');
      }

      tl
        .fromTo(reasonsBtn, {
          opacity: 0,
          y: 10,
        }, {
          duration: 0.8,
          opacity: 1,
          y: 0,
        }, '>-0.4');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.preferEmail': function (value) {
      if (value) {
        this.form.preferPhone = false;
        return;
      }
      this.form.preferPhone = true;
    },
    // eslint-disable-next-line func-names
    'form.preferPhone': function (value) {
      if (value) {
        this.form.preferEmail = false;
        return;
      }
      this.form.preferEmail = true;
    },
  },
  mixins: [navigationInit],
};
</script>

<style src="@/styles/views/homeView.scss" lang="scss" scoped/>
