<template>
  <DotsSlider :items="items"/>
</template>

<script>
import screenSizeInit from '@/mixins/screenSizeInit';
import DotsSlider from '@/components/DotsSlider.vue';

export default {
  name: 'NumbersSliderRU',
  components: { DotsSlider },
  data: () => ({
    desktopItems: [
      '/static/images/numbersSlider/0.jpg',
      '/static/images/numbersSlider/1.png',
      '/static/images/numbersSlider/2.png',
      '/static/images/numbersSlider/3.png',
      '/static/images/numbersSlider/4.png',
    ],
    mobileItems: [
      '/static/images/numbersSlider/0_mobile.jpg',
      '/static/images/numbersSlider/1_mobile.png',
      '/static/images/numbersSlider/2_mobile.png',
      '/static/images/numbersSlider/3_mobile.png',
      '/static/images/numbersSlider/4_mobile.png',
    ],
  }),
  computed: {
    items() {
      return this.screen.width <= this.screenWidthBreakPoints.md
        ? this.mobileItems
        : this.desktopItems;
    },
  },
  methods: {
  },
  mixins: [screenSizeInit],
};
</script>

<style scoped lang="scss">
</style>
