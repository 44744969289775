<template>
  <div class="investments" id="investments">

    <WelcomeBlockRU />
    <MarketAboutBlockRU />
    <CompetitionBlockRU />
    <MarketNumbersBlockRU />
    <LifeQualityBlockRU />
    <ObjectsBlock :items="items" />
    <ClosedSalesRU />

    <ContactsBlock class="investments__contacts" />
  </div>
</template>

<script>
import ContactsBlock from '@/components/ContactsBlock.vue';
import navigationInit from '@/mixins/navigationInit';
import ObjectsBlock from '@/components/objects/ObjectsBlock.vue';
import WelcomeBlockRU from '@/components/views/investments/welcome-block/WelcomeBlockRU.vue';
import MarketAboutBlockRU from '@/components/views/investments/market-about/MarketAboutBlockRU.vue';
import CompetitionBlockRU
  from '@/components/views/investments/competition-block/CompetitionBlockRU.vue';
import MarketNumbersBlockRU
  from '@/components/views/investments/market-numbers/MarketNumbersBlockRU.vue';
import LifeQualityBlockRU from '@/components/views/investments/life-quality/LifeQualityBlockRU.vue';
import ClosedSalesRU from '@/components/views/investments/closed-sales/ClosedSalesRU.vue';

export default {
  name: 'InvestmentsRU',
  components: {
    ClosedSalesRU,
    LifeQualityBlockRU,
    MarketNumbersBlockRU,
    CompetitionBlockRU,
    MarketAboutBlockRU,
    WelcomeBlockRU,
    ObjectsBlock,
    ContactsBlock,
  },
  data: () => ({
    headerBlocks: [
      'investments',
      'contacts',
    ],
    items: [
      {
        id: 'objectID-1',
        title: 'Ellington Beach House',
        name: 'Dubai, East Crescent, Palm Jumeirah',
        price: 'от 1 471 185 $',
        text: 'Ellington Beach House является олицетворением нового стиля жизни в большом городе. Полукруглая форма жилого комплекса, напоминающего обращённую к морю яхту, вдохновлена траекторией движения солнца, и позволяет ежедневно наблюдать восход над Персидским заливом и закат над панорамой Дубая.',
        imgSrc1: '/static/images/objects/content/1_1.png',
        imgSrc2: '/static/images/objects/content/1_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/1.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-2',
        title: 'Hills Park',
        name: 'Dubai Hills Estate',
        price: 'от 333 393 $',
        text: 'Новый жилой комплекс, расположенный в зелёном сердце Дубая, рядом с парком Dubai Hills Park и гольф-клубом, в нескольких минутах от центра города и других деловых, торговых и туристических центров. Островок природы в шумном мегаполисе.',
        imgSrc1: '/static/images/objects/content/2_1.png',
        imgSrc2: '/static/images/objects/content/2_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/2.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-3',
        title: 'Marbella',
        name: 'Damac Lagoons',
        price: 'от 520 500 $',
        text: 'Marbella – таунхаусы, вдохновлённые атмосферой одноимённого испанского курортного города. Белоснежные экстерьеры домов, панорамные окна, природные цвета – все здесь напоминает о Средиземноморье.',
        imgSrc1: '/static/images/objects/content/3_1.png',
        imgSrc2: '/static/images/objects/content/3_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/3.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-4',
        title: 'Peninsula Four, The Plaza',
        name: 'Dubai, Business Bay',
        price: 'от 266 575 $',
        text: 'Концептуально спланированный жилой комплекс в одном из самых привлекательных районов Дубая. Две башни высотой в 52 этажа с премиальными апартаментами. Архитектура и расположение зданий проекта тщательно продуманы и образуют максимально удобное для жильцов пространство.',
        imgSrc1: '/static/images/objects/content/4_1.png',
        imgSrc2: '/static/images/objects/content/4_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/4.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-5',
        title: 'Rivera',
        name: 'Dubai, Meydan',
        price: 'от 268 767 $',
        text: 'Жилой комплекс Riviera — сочетание средиземноморского дизайна и современной архитектуры. Несколько жилых домов средней этажности, разделены на студии и квартиры с одной, двумя и тремя спальнями. Комплекс построен в районе с развитой социальной и торговой инфраструктурой. Из окон квартир открывается потрясающий вид на лагуну и пляжи с белым песком. Здесь вы сможете почувствовать себя как дома и стать частью разнообразного и мультикультурного сообщества.',
        imgSrc1: '/static/images/objects/content/5_1.png',
        imgSrc2: '/static/images/objects/content/5_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/5.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-6',
        title: 'Creek Vista Heights',
        name: 'Sobha Hartland',
        price: 'от 345 205 $',
        text: 'Две высотные башни, расположенные вблизи от оживлённого центра Дубая. Жилой комплекс с высококлассной отделкой и неповторимой атмосферой. Панорамные окна во всю стену открывают великолепные виды на Бурдж-Халифа, Даунтаун Дубай и заповедник дикой природы Рас-Аль-Хор.',
        imgSrc1: '/static/images/objects/content/6_1.png',
        imgSrc2: '/static/images/objects/content/6_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/6.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-7',
        title: 'The Opus Residences',
        name: 'Dubai, Business Bay',
        price: 'от 1 124 292 $',
        text: 'Проект The Opus Residences – новый жилой комплекс в самом центре Дубая, с фантастическим видом на небоскреб Бурдж-Халифа и Персидский залив. Находится в непосредственной близости от Дубайского международного финансового центра и торгового центра Dubai Mall. Одна из последних работ всемирно известного архитектора Захи Хадид. Каждая квартира – произведение искусства, интерьеры комплекса обставлены мебелью, индивидуально подобранной самим архитектором.',
        imgSrc1: '/static/images/objects/content/7_1.png',
        imgSrc2: '/static/images/objects/content/7_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/7.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
      {
        id: 'objectID-8',
        title: 'Vincitore Volare',
        name: 'Dubai, Golden Quarter',
        price: 'от 170 000 $',
        text: 'Vincitore Volare - единственный застройщик, которые гарантирует чистую доходность от аренды в 8% годовых после сдачи проекта. Комплекс выполнен в стилистике итальянских и французских домов, архитекторы и дизайнеры вдохновлялись викторианской архитектурой. Дом расположен в обжитом районе Дубая Arjan. В пешей доступности от дома находятся школы, образовательные учреждения для детей, торговые центры и клиники.',
        imgSrc1: '/static/images/objects/content/8_1.png',
        imgSrc2: '/static/images/objects/content/8_2.png',
        preview: {
          imgSrc: '/static/images/objects/preview/8.png',
          moreURL: 'https://t.me/+79817394249',
        },
      },
    ],
  }),
  mixins: [navigationInit],
};
</script>

<style scoped lang="scss" src="@/styles/views/investmentsView.scss">
</style>
