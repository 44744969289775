<template>
  <div class="ui-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UIContainer',
};
</script>

<style lang="scss">
.ui-container {
  margin: 0 auto;
  width: 100%;
  max-width: 1320px;
  position: relative;
  padding: 0 70px;
  @include media-down($md) {
    padding: 0 32px;
  }
}
</style>
