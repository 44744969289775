<template>
  <div class="about-slider" ref="slider">
    <div
      class="about-slider__content"
      ref="content"
    >
      <div
        class="about-slider__item"
        v-for="(item) in items"
        ref="item"
        :class="{'is-dragging': dragAndDrop.active}"
        :key="item.id"
      >
        <div class="about-slider__item-content">
          <img :src="item.src" alt="src" draggable="false">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'AboutSlider',
  mounted() {
    this.initSlider();
    window.addEventListener('resize', this.initSlider);

    const { content } = this.$refs;

    content.addEventListener('mousedown', this.startMove);
    content.addEventListener('touchstart', this.startMove);

    content.addEventListener('mousemove', this.move);
    content.addEventListener('touchmove', this.move);

    content.addEventListener('mouseleave', this.endMove);
    content.addEventListener('mouseup', this.endMove);
    content.addEventListener('touchend', this.endMove);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initSlider);
  },
  data() {
    return {
      dragAndDrop: {
        active: false,
        startX: null,
        endX: null,
        startMouseX: null,
      },
      index: 1,
      items: [
        {
          src: '/static/images/aboutSlider/1.png',
          id: 'about1',
        },
        {
          src: '/static/images/aboutSlider/2.png',
          id: 'about2',
        },
        {
          src: '/static/images/aboutSlider/3.png',
          id: 'about3',
        },
        {
          src: '/static/images/aboutSlider/4.png',
          id: 'about4',
        },
        {
          src: '/static/images/aboutSlider/5.png',
          id: 'about5',
        },
        {
          src: '/static/images/aboutSlider/6.png',
          id: 'about6',
        },
        {
          src: '/static/images/aboutSlider/1.png',
          id: 'about7',
        },
        {
          src: '/static/images/aboutSlider/2.png',
          id: 'about8',
        },
        {
          src: '/static/images/aboutSlider/3.png',
          id: 'about9',
        },
        {
          src: '/static/images/aboutSlider/4.png',
          id: 'about10',
        },
        {
          src: '/static/images/aboutSlider/5.png',
          id: 'about11',
        },
        {
          src: '/static/images/aboutSlider/6.png',
          id: 'about12',
        },
      ],
      initItemsLength: null,
      itemWidth: null,
      itemsInView: null,
      animation: null,
    };
  },
  computed: {
    ...mapGetters(['isModalActive']),
    isSliderModalActive() {
      return this.isModalActive('slider');
    },
    maxIndex() {
      return this.items.length;
    },
    maxX() {
      return this.maxIndex * this.itemWidth;
    },
  },
  methods: {
    ...mapMutations(['openModal']),
    openSliderModal(data) {
      this.openModal({ name: 'slider', data });
    },
    startMove(e) {
      this.dragAndDrop.active = true;
      this.dragAndDrop.startMouseX = e.pageX || e.touches[0].pageX;
      this.dragAndDrop.startX = parseInt(gsap.getProperty(this.$refs.content, 'x', 'px'), 10);
    },
    move(e) {
      if (!this.dragAndDrop.active) return;

      const endMouseX = e.pageX || e.touches[0].pageX;
      const dist = (endMouseX - this.dragAndDrop.startMouseX);
      let x = this.dragAndDrop.startX + dist;

      // x = gsap.utils.clamp(-this.maxX, 0, x);

      if (x <= -this.itemWidth) {
        this.items.push(this.items.shift());
        x += this.itemWidth;
        this.dragAndDrop.startX += this.itemWidth;
      }
      if (x > 0) {
        this.items.unshift(this.items.pop());
        x -= this.itemWidth;
        this.dragAndDrop.startX -= this.itemWidth;
      }

      this.dragAndDrop.endX = x;
      gsap.set(this.$refs.content, { x });
    },
    endMove(event) {
      event.preventDefault();

      this.dragAndDrop.active = false;

      const diff = Math.abs(Math.abs(this.dragAndDrop.endX) - Math.abs(this.dragAndDrop.startX));

      if (diff > 5) return;
      if (!event.target?.src) return;

      this.openImg(event.target.src);
    },
    initSlider() {
      this.index = 1;
      gsap.set(this.$refs.content, {
        x: 0,
      });

      const item = this.$refs.item[0];

      this.initItemsLength = this.items.length;
      this.itemWidth = gsap.getProperty(item, 'width')
        + gsap.getProperty(item, 'margin-left') * 2;
      this.itemsInView = Math.ceil(gsap.getProperty(this.$refs.slider, 'width') / this.itemWidth);
    },
    openImg(src) {
      this.openSliderModal({
        src,
      });
    },
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
.about-slider {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  z-index: 1;
  width: 100%;
  &__content {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    user-drag: none;
    user-select: none;
  }
  &__item {
    width: 280px;
    margin: 0 8px;
    cursor: pointer;
    @include media-down($md) {
      width: 200px;
    }
    &:hover {
      z-index: 9999;
      .about-slider__item-content {
        //transform: scale(1.2);
        opacity: 0.8;
      }
    }
  }
  &__item-content {
    transition: 0.3s ease-out;
  }
}
.is-dragging {
  cursor: grab;
}
</style>
