import { mapActions, mapMutations } from 'vuex';

const navigationInit = {
  // REQUIRED "headerBlocks" IN COMPONENT!!!
  // data: () => ({
  //   headerBlocks: [],
  // }),
  mounted() {
    this.initBlocksCoords();

    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.initBlocksCoords);

    this.onScroll();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.initBlocksCoords.bind(this, this.headerBlocks));
  },
  methods: {
    ...mapMutations(['initBlocksCoords']),
    ...mapMutations({
      m_initBlocksCoords: 'initBlocksCoords',
    }),
    ...mapActions(['onScroll']),
    initBlocksCoords() {
      this.m_initBlocksCoords(this.headerBlocks);
    },
  },
};

export default navigationInit;
