<template>
  <UIHeader />
  <router-view/>
  <SliderModal />
  <ContactModal />
  <CommentModal />
  <ResponseModal />
  <UIFooter />
</template>

<script>
import UIFooter from '@/components/UI/UIFooter.vue';
import ResponseModal from './components/ResponseModal.vue';
import UIHeader from './components/UI/UIHeader.vue';
import SliderModal from './components/views/home/about/SliderModal.vue';
import ContactModal from './components/ContactModal.vue';
import CommentModal from './components/CommentModal.vue';

export default {
  components: {
    UIFooter,
    CommentModal,
    ContactModal,
    SliderModal,
    ResponseModal,
    UIHeader,
  },
  data: () => ({
    userID: null,
  }),
  mounted() {
    window.AMOPIXEL_IDENTIFIER_PARAMS = window.AMOPIXEL_IDENTIFIER_PARAMS || {};
    // eslint-disable-next-line camelcase,func-names
    window.AMOPIXEL_IDENTIFIER_PARAMS.onload = function (pixel_identifier) {
      window.visitorUID = pixel_identifier.getVisitorUid();
      // console.log('visitor_uid', this.userID);
    };
  },
};
</script>

<style lang="scss">
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
#app {
  width: 100vw;
  overflow-x: hidden;
  color: #FFF;
  font-family: 'Graphik LCG', sans-serif;
  font-style: normal;
  font-weight: 400;
  position: relative;
  background: #112637;
}
* {
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.no-scroll {
  overflow: hidden;
}

a,
a:active,
a:visited,
a:link {
  color: #000;
  text-decoration: none;
}
</style>
