const screenSizeInit = {
  data: () => ({
    screenWidthBreakPoints: {
      xxl: 1400,
      xl: 1200,
      lg: 992,
      md: 768,
      sm: 576,
      xsm: 360,
    },
    screen: {
      width: null,
      height: null,
    },
  }),
  mounted() {
    window.addEventListener('resize', this.initScreenWidth);
    this.initScreenWidth();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initScreenWidth);
  },
  methods: {
    initScreenWidth() {
      const win = window;
      const doc = document;
      const docElem = doc.documentElement;
      const body = doc.getElementsByTagName('body')[0];

      this.screen.width = win.innerWidth || docElem.clientWidth || body.clientWidth;
      this.screen.height = win.innerHeight || docElem.clientHeight || body.clientHeight;
    },
  },
};

export default screenSizeInit;
