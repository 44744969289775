<template>
  <div class="ui-slider">
    <div class="ui-slider__container" ref="container">
      <div class="ui-slider__content" ref="content">
        <div
          class="ui-slider__item"
          v-for="item in items"
          :key="item.id"
          ref="item"
          v-bind="$attrs"
        >
          <slot :item="item"/>
        </div>
      </div>

      <UISliderArrow
        class="ui-slider__control ui-slider__control--left"
        :class="{'hidden': this.curIndex === 0}"
        @click="swipe(this.curIndex - 1)"
      />
      <UISliderArrow
        class="ui-slider__control ui-slider__control--right"
        :class="{'hidden': this.curIndex === this.maxIndex}"
        @click="swipe(this.curIndex + 1)"
      />

    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import UISliderArrow from '@/components/UI/UISliderArrow.vue';

export default {
  inheritAttrs: false,
  name: 'UISlider',
  components: { UISliderArrow },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    curIndex: 0,
    itemWidth: null,
    itemsInView: null,
    timer: null,
  }),
  computed: {
    maxIndex() {
      return this.items.length - this.itemsInView;
    },
  },
  mounted() {
    this.initSlider();
    window.addEventListener('resize', this.initSlider);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initSlider);
  },
  methods: {
    initSlider() {
      this.curIndex = 0;
      this.swipe(0);

      if (!this.$refs.item) {
        this.timer = setTimeout(this.initSlider, 200);
        return;
      }
      this.itemWidth = gsap.getProperty(this.$refs.item[0], 'width');
      this.itemsInView = Math.floor(gsap.getProperty(this.$refs.container, 'width') / this.itemWidth);

      this.itemWidth += gsap.getProperty(this.$refs.item[0], 'margin-right');
    },
    swipe(index) {
      this.curIndex = index;
      this.curIndex = gsap.utils.clamp(0, this.maxIndex, this.curIndex);

      gsap.to(this.$refs.content, {
        duration: 0.7,
        ease: 'power1.out',
        x: -this.curIndex * this.itemWidth,
      });
    },
  },
};
</script>

<style lang="scss">
.ui-slider {
  position: relative;
  width: 100%;
  &__container {
    overflow: hidden;
  }
  &__content {
    display: flex;
    width: 100%;
  }
  &__control {
    position: absolute;
    top: 50%;
    background: rgba(255, 255, 255, 0.15);

    @include media-down($xl) {
      font-size: 36px;
    }

    &--right {
      right: -50px;
      transform: rotate(180deg) translateY(50%) translateX(-100%);

      @include media-down($xl) {
        right: -12px;
      }

      @include media-down($md) {
        right: 30px;
      }

      @include media-down($sm) {
        right: 18px;
      }
    }
    &--left {
      left: -50px;
      transform: translateY(-50%) translateX(-100%);

      @include media-down($xl) {
        left: -12px;
      }

      @include media-down($md) {
        left: 22px;
      }

      @include media-down($sm) {
        left: 18px;
      }
    }
  }
  &__control.hidden {
    opacity: 0;
  }
  &__arrow {
    width: 29px;
    @include media-down($xl) {
      width: 20px;
    }
    @include media-down($sm) {
      width: 18px;
    }
  }
}
</style>
