<template>
  <DotsSlider :items="items" :shadow="false"/>
</template>

<script>
import DotsSlider from '@/components/DotsSlider.vue';
import { mapState } from 'vuex';

export default {
  name: 'ServicesSlider',
  components: { DotsSlider },
  data: () => ({
    itemsRU: [
      {
        imageURL: '/static/images/servicesSlider/1_Sofia_Litvinenko.jpg',
        name: 'Софья Литвиненко',
        position: 'Директор агентства',
      },
      {
        imageURL: '/static/images/servicesSlider/1.jpg',
        name: 'Надежда Никитенкова',
        position: 'Директор отдела продаж',
      },
      {
        imageURL: '/static/images/servicesSlider/3_Pavel_Emelyanov.jpeg',
        name: 'Павел Емельянов',
        position: 'Директор по стратегическому развитию',
      },
      {
        imageURL: '/static/images/servicesSlider/4_Natalia_Shilimina.jpg',
        name: 'Наталия Шилимина',
        position: 'Менеджер по продажам',
      },
      {
        imageURL: '/static/images/servicesSlider/5_Mark_Mishchenko.jpg',
        name: 'Марк Мищенко',
        position: 'Менеджер по продажам',
      },
      {
        imageURL: '/static/images/servicesSlider/6_Yulia_Agapova.jpg',
        name: 'Юлия Агапова',
        position: 'Менеджер по продажам',
      },
      {
        imageURL: '/static/images/servicesSlider/7_Bekzod_Tulkinov.jpeg',
        name: 'Бекзот Тулкинов',
        position: 'Менеджер по продажам',
      },
    ],
    itemsEN: [
      {
        imageURL: '/static/images/servicesSlider/1_Sofia_Litvinenko.jpg',
        name: 'Софья Литвиненко',
        position: 'CEO',
      },
      {
        imageURL: '/static/images/servicesSlider/1.jpg',
        name: 'Nadezhda Nikitenkova',
        position: 'Head of Sales',
      },
      {
        imageURL: '/static/images/servicesSlider/3_Pavel_Emelyanov.jpeg',
        name: 'Pavel Emelyanov',
        position: 'Director of Strategic Development',
      },
      {
        imageURL: '/static/images/servicesSlider/4_Natalia_Shilimina.jpg',
        name: 'Natalia Shilimina',
        position: 'Sales Manager',
      },
      {
        imageURL: '/static/images/servicesSlider/5_Mark_Mishchenko.jpg',
        name: 'Mishchenko Mark',
        position: 'Sales Manager',
      },
      {
        imageURL: '/static/images/servicesSlider/6_Yulia_Agapova.jpg',
        name: 'Yulia Agapova',
        position: 'Sales Manager',
      },
      {
        imageURL: '/static/images/servicesSlider/7_Bekzod_Tulkinov.jpeg',
        name: 'Bekzod Tulkinov',
        position: 'Sales Manager',
      },
    ],
  }),
  computed: {
    ...mapState(['language']),
    isEnglish() {
      return this.language === 'en';
    },
    items() {
      return this.isEnglish
        ? this.itemsEN
        : this.itemsRU;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
