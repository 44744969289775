<template>
  <div
    class="contacts-checkbox"
    @click="toggle"
    :class="{
      'error': hasError,
      'checked': checked,
    }"
  >
    <div
      class="contacts-checkbox__checked"
    />
  </div>
</template>

<script>
export default {
  name: 'ContactsCheckbox',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit('update:checked', !this.checked);
    },
  },
};
</script>

<style scoped lang="scss">
.contacts-checkbox {
  position: relative;
  $size: 14px;
  width: $size;
  height: $size;
  border: 1px solid #DDDDDD;
  cursor: pointer;
  transition: 0.4s ease-out;
  @include media-down($lg) {
    $size: 12px;
    min-width: $size;
    min-height: $size;
    width: $size;
    height: $size;
    border: 1px solid #DDDDDD;
  }
}

.checked {
  background-color: #D4FF59;
  border: 1px solid #D4FF59;
}
.error {
  border-color: #f9623b;
}
</style>
