<template>
  <div class="competition">
    <div class="competition__inner">
      <div class="competition__text">
      <span class="highlight">
        Приток инвесторов увеличивает конкуренцию. Выгодные предложения закрываются очень быстро.
        Для того, чтобы успевать их отслеживать, проверять и оформлять, нужно держать руку
        на пульсе. Знать язык, местный менталитет, выстраивать долгосрочные отношения
        с застройщиками, девелоперами. Это именно то, чем мы занимаемся.
      </span>
        <br><br>
        <span>
        Удачным вложением может стать прямая сделка с застройщиком. <br>Как правило, в Дубае
        недвижимость классов бизнес / премиум / элит действительно соответствует заявленной
        планке. В общественных пространствах есть бассейны,спортзалы коворкинги, зоны джакузи,
        детские площадки, барбекю и полный набор других инфраструктурных объектов.
      </span>
      </div>
      <div class="competition__img">
        <img src="@/assets/images/investments/competition.png" alt="competition">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompetitionBlockRU',
};
</script>

<style scoped lang="scss">
.competition {
  display: flex;
  justify-content: center;

  &__inner {
    margin: 0 16px;
    padding: 54px;
    max-width: 1087px;
    width: 100%;
    display: flex;
    background: #204A6C;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    top: -90px;
    overflow: hidden;

    @include media-down($md) {
      max-width: none;
      margin: 0 0 53px;
      padding: 42px 42px 180px;
      top: 0;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    max-width: 530px;

    @include media-down($md) {
      font-size: 14px;
      line-height: 18px;
    }

    .highlight {
      font-weight: 600;
    }
  }

  &__img {
    width: 532px;
    position: absolute;
    top: -70px;
    right: -80px;

    @include media-down($md) {
      width: 338px;
      transform: rotate(17deg);
      top: auto;
      bottom: -130px;
      right: auto;
      left: 20px;
    }
  }
}
</style>
