<template>
  <div class="contacts" id="contacts">
    <UIContainerShrunk class="contacts__container">
      <div class="contacts__title-container">
        <div class="contacts__title">
          {{
            isEnglish
              ? 'Contacts'
              : 'Контакты'
          }}
        </div>
      </div>
      <div class="contacts__content">
        <div class="contacts-info__container">
          <div class="contacts__title-mobile">
            {{
              isEnglish
                ? 'Contacts'
                : 'Контакты'
            }}
          </div>
          <div class="contacts-info">
            <div class="contacts-info__phones">
              <div class="contacts-info__subtitle">LLC “Krönung real estate”</div>
              <div class="contacts-info__phone">
                <a class="link link-phone" href="tel:+971581277655" target="_blank">
                  +971 58 127 7655
                </a>
                <br>
                <a class="link link-phone" href="tel:+79817394249" target="_blank">
                  +7 981 739-42-49
                </a>
                <a class="link" href="https://wa.me/79817394249" target="_blank">&nbsp;WhatsApp</a>,
                <a class="link" href="https://t.me/+79817394249" target="_blank">Telegram</a>
              </div>
            </div>
            <div class="contacts-info__address">
              <span style="font-weight: 600">{{ isEnglish ? 'Address:' : 'Адрес:' }} </span>
              Dubai, Business Bay,
              <br>
              The Binary by Omniyat, office 910
            </div>
            <div class="contacts-info__socials">
              <a class="contacts-info__social" href="https://www.facebook.com/profile.php?id=100086594198311" target="_blank">
                <img src="@/assets/images/contacts/fb.svg" alt="social">
              </a>
              <a class="contacts-info__social" href="https://t.me/KronungDubai" target="_blank">
                <img src="@/assets/images/contacts/tg.svg" alt="social">
              </a>
            </div>
            <div class="contacts-info__map">
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12143.932167251722!2d55.27836883297541!3d25.189303417888453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f682553824981%3A0x53bbe1f0faef0249!2zT21uaXlhdCAtIEFsIEFzYXllbCBTdCAtIEJ1c2luZXNzIEJheSAtIER1YmFpIC0g0J7QsdGK0LXQtNC40L3QtdC90L3Ri9C1INCQ0YDQsNCx0YHQutC40LUg0K3QvNC40YDQsNGC0Ys!5e0!3m2!1sru!2sru!4v1666137897201!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>

        <div class="contacts-info__map-mobile">
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2146.7981736208667!2d55.266132957160835!3d25.187375303562355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69bdf146c4ab%3A0x597c7c2a8a7393d!2sThe%20Binary%20tower!5e0!3m2!1sru!2sru!4v1667163617350!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <!--          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12143.932167251722!2d55.27836883297541!3d25.189303417888453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f682553824981%3A0x53bbe1f0faef0249!2zT21uaXlhdCAtIEFsIEFzYXllbCBTdCAtIEJ1c2luZXNzIEJheSAtIER1YmFpIC0g0J7QsdGK0LXQtNC40L3QtdC90L3Ri9C1INCQ0YDQsNCx0YHQutC40LUg0K3QvNC40YDQsNGC0Ys!5e0!3m2!1sru!2sru!4v1666137897201!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>-->
        </div>

        <div class="contacts-form__container">
          <div class="contacts-form">
            <UIInput
              class="contacts-form__input"
              :placeholder="isEnglish ? 'Name*' : 'Имя*'"
              v-model:value="form.name"
              :hasError="errors.name && highlightErrors"
            />
            <UIInput
              class="contacts-form__input"
              :placeholder="isEnglish ? 'Email*' : 'Почта*'"
              v-model:value="form.email"
              :hasError="errors.email && highlightErrors"
            />
            <UIInput
              class="contacts-form__input"
              :placeholder="isEnglish ? 'Phone number' : 'Телефон'"
              v-model:value="form.phone"
            />
            <div class="contacts-form__preferences">
              <div class="contacts-form__preference">
                <UICheckbox
                  class="contacts-form__checkbox"
                  v-model:checked="form.preferEmail"
                />
                <div class="contacts-form__title">
                  {{ isEnglish ? 'Prefer email' : 'удобнее по почте' }}
                </div>
              </div>
              <div class="contacts-form__preference">
                <UICheckbox
                  class="contacts-form__checkbox"
                  v-model:checked="form.preferPhone"
                />
                <div class="contacts-form__title">
                  {{ isEnglish ? 'Prefer phone' : 'удобнее по телефону' }}
                </div>
              </div>
            </div>
            <div class="contacts-form__comments">
              <div class="contacts-form__title">
                {{ isEnglish ? 'Comments' : 'Комментарии' }}
              </div>
              <textarea
                v-model="form.comments"
                class="contacts-form__comments-textarea"
                rows="4"
              />
            </div>
            <div class="contacts-form__agreement">
              <UICheckbox
                class="contacts-form__checkbox"
                v-model:checked="form.agreement"
                :hasError="errors.agreement && highlightErrors"
              />
              <div class="contacts-form__title">
                {{
                  isEnglish
                    ? 'Agree with the processing of personal data'
                    : 'согласен с обработкой персональных данных'
                }}
              </div>
            </div>
            <div class="contacts-form__btn-container">
              <UIButton @click="send">
                {{ isEnglish ? 'Send' : 'Отправить' }}
              </UIButton>
            </div>
          </div>
        </div>
      </div>
    </UIContainerShrunk>
  </div>
</template>

<script>
import UIInput from '@/components/UI/UIInput.vue';
import UICheckbox from '@/components/UI/UICheckbox.vue';
import UIButton from '@/components/UI/UIButton.vue';
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import validateEmail from '@/utils/validateEmail';
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'ContactsBlock',
  components: {
    UIInput,
    UICheckbox,
    UIButton,
    UIContainerShrunk,
  },
  data: () => ({
    highlightErrors: false,
    form: {
      name: '',
      email: '',
      phone: '',
      comments: '',
      preferEmail: true,
      preferPhone: false,
      agreement: true,
    },
  }),
  computed: {
    ...mapState(['language']),
    isEnglish() {
      return this.language === 'en';
    },
    errors() {
      const { form } = this;
      return {
        name: !form.name,
        email: !form.email || !validateEmail(form.email),
        agreement: !form.agreement,
      };
    },
    hasErrors() {
      return !!Object.values(this.errors).filter(Boolean).length;
    },
  },
  methods: {
    ...mapActions(['sendContact']),
    // eslint-disable-next-line func-names
    send: debounce(function () {
      clearTimeout(this.errorsTimer);
      if (this.hasErrors) {
        this.highlightErrors = true;

        this.errorsTimer = setTimeout(() => {
          this.highlightErrors = false;
        }, 5000);
        return;
      }

      this.sendContact({
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        comments: this.form.comments,
        preferEmail: this.form.preferEmail,
      });

      // eslint-disable-next-line no-undef
      ym(90993598, 'reachGoal', 'formSubmit');
    }, 500),
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.preferEmail': function (value) {
      if (value) {
        this.form.preferPhone = false;
        return;
      }
      this.form.preferPhone = true;
    },
    // eslint-disable-next-line func-names
    'form.preferPhone': function (value) {
      if (value) {
        this.form.preferEmail = false;
        return;
      }
      this.form.preferEmail = true;
    },
  },
};
</script>

<style lang="scss">
.contacts {
  padding-top: 104px;
  padding-bottom: 86px;
  background-image: url("~@/assets/images/contacts/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-down($md) {
    padding-top: 0;
    padding-bottom: 60px;
    background: #112637;
  }
  &__container {
  }
  &__content {
    display: flex;
    @include media-down($md) {
      flex-direction: column;
    }
  }
  &__title {
    margin-bottom: 33px;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    width: 100%;
    @include media-down($lg) {
      font-size: 28px;
      line-height: 38px;
    }
    @include media-down($md) {
      display: none;
    }
  }
  &__title-container {
    //max-width: none;
  }
  &__title-mobile {
    display: none;
    margin-bottom: 28px;
    font-size: 24px;
    font-weight: 500;
    line-height: 42px;
    width: 100%;
    @include media-down($md) {
      display: block;
      padding: 0 40px;
    }
  }
  .contacts-info {
    margin-right: 36px;
    font-size: 18px;
    line-height: 27px;
    flex-grow: 1;
    @include media-down($xl) {
      //margin-right: 70px;
    }
    @include media-down($lg) {
      margin-right: 30px;
      font-size: 16px;
      line-height: 24px;
    }
    @include media-down($md) {
      font-size: 14px;
      line-height: 18px;
      margin-right: 0;
      margin-bottom: 0;
      padding: 0 40px;
    }
    &__container {
      padding-right: 0;
      @include media-down($md) {
        background-image: url("~@/assets/images/contacts/bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 50px;
        width: 100vw;
        left: -40px;
        position: relative;
      }
      @include media-down($sm) {
        background-position: 15% center;
      }
    }
    &__phones {
      margin-bottom: 40px;
    }
    &__subtitle {
      margin-bottom: 16px;
      font-weight: 600;
      @include media-down($md) {
        margin-bottom: 20px;
      }
    }
    &__phone {}
    &__address {
      margin-bottom: 30px;
    }
    &__socials {
      margin-bottom: 48px;
      display: flex;
    }
    &__social {
      width: 47px;
      transition: 0.4s ease-out;
      cursor: pointer;
      display: block;
      @include media-down($lg) {
        width: 40px;
      }
      @include media-down($md) {
        width: 47px;
      }
      &:hover {
        opacity: 0.6;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
    &__map {
      display: flex;
      iframe {
        width: 470px;
        max-height: 300px;
        -webkit-filter: grayscale(80%); /* Safari 6.0 - 9.0 */
        filter: grayscale(80%);
        @include media-down($xl) {
          width: 440px;
        }
        @include media-down($lg) {
          width: 360px;
          max-height: 260px;
        }
        @include media-down($md) {
          display: none;
        }
      }
    }
    &__map-mobile {
      width: 100%;
      display: none;
      margin-bottom: 68px;
      @include media-down($md) {
        display: block;
        width: 100vw;
        left: -40px;
        position: relative;
      }
      iframe {
        max-height: 226px;
        width: 100%;
        filter: grayscale(80%);
      }
    }
  }
  .contacts-form {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    &__container {
      padding: 78px 54px;
      background: rgba(1, 8, 15, 0.4);
      backdrop-filter: blur(4px);
      border-radius: 8px 0px 0px 8px;
      min-width: 496px;
      width: 100%;
      @include media-down($lg) {
        min-width: auto;
        padding: 40px 40px;
      }

      @include media-down($md) {
        background: none;
        padding: 0;
      }
    }
    &__title {
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      @include media-down($lg) {
        line-height: 22px;
      }
      @include media-down($md) {
        line-height: 17px;
      }
    }
    &__input {
      margin-bottom: 16px;
      width: 48%;
      &:first-child {
        flex: 1 1 100%;
      }
      @include media-down($md) {
        //margin-bottom: 26px;
        flex: 1 1 100%;
      }
    }
    &__preferences {
      margin-bottom: 26px;
      width: 100%;
      display: flex;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-down($lg) {
        flex-direction: column;
      }
      @include media-down($md) {
        margin-bottom: 18px;
      }
    }
    &__preference {
      width: 48%;
      display: flex;
      align-items: center;
      @include media-down($xl) {
        //width: 50%;
      }
      @include media-down($lg) {
        width: 100%;
      }
      &:first-child {
        @include media-down($lg) {
          margin-bottom: 25px;
        }
      }
    }
    &__checkbox {
      margin-right: 8px;
    }
    &__comments {
      width: 100%;
    }
    &__comments-textarea {
      margin-top: 12px;
      border: 1px solid #DDDDDD;
      outline: none;
      background: transparent;
      color: #FFFFFF;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      font-family: "Graphik LCG", sans-serif;
      @include media-down($md) {
        margin-top: 0;
      }
    }
    &__agreement {
      margin-top: 14px;
      margin-bottom: 52px;
      display: flex;
      align-items: center;
      width: 100%;
      @include media-down($md) {
        margin-bottom: 20px;
      }

      .contacts-checkbox {
        //@include media-down($md) {
        //  align-self: flex-start;
        //}
      }
    }
    &__btn-container {
      justify-content: center;
      display: flex;
      width: 100%;

      @include media-down($md) {
      }
    }
  }
}
</style>
