<template>
  <div class="home" id="about">
    <div class="greetings">
      <UIContainer class="greetings__content">
        <div class="greetings__title">
          Krönung <br> Real Estate
        </div>
        <div class="greetings__delimiter" />
        <div class="greetings__text">
          we are where you want to live
        </div>
      </UIContainer>
<!--      <div class="greetings__arrow">-->
<!--        <div class="greetings__arrow-content">-->
<!--          <img src="@/assets/images/home/greetings/arrow.svg" alt="arrow">-->
<!--        </div>-->
<!--      </div>-->
      <ScrollArrow class="greetings__arrow" />
    </div>

    <div class="about">
      <div class="about__content">
        <div class="about__title">About us</div>
        <div class="about__logo">
          <img src="@/assets/images/home/about/logo.svg" alt="logo">
        </div>
        <div class="about__text">
          <span class="highlight">
            We have been working since 2004 <span class="glue">in the residential</span>
            <span class="glue"> and commercial</span> real estate markets
            <span class="glue"> of Russia,</span> Spain, Mexico and the United Arab Emirates.
            <span class="glue"> Our portfolio</span> includes construction projects
            <span class="glue"> for the construction</span>
            <span class="glue"> of residential</span> buildings, shops, shopping centers
            <span class="glue"> as well as real</span> estate investment management.
            This is the formal side <span class="glue">of the activities</span>
            <span class="glue"> of the Krönung Real Estate agency.</span>
          </span>
          <br><br>

          Despite having <span class="glue">a lot of experience,</span> our team
          <span class="glue"> is young:</span> everybody is between their thirties
          <span class="glue"> to forties.</span> We are used to acting quickly, accurately
          <span class="glue"> and systematically.</span>
          <br><br>

          A flexible approach comes in handy in Dubai. Currently, this market is receives waves
          <span class="glue"> of investors</span> from all over the planet because
          <span class="glue"> the Emirates</span> have created attractive
          <span class="glue"> and comfortable</span> conditions for international business
          <span class="glue"> and expats.</span>
          <br><br>

          Great deals regularly appear <span class="glue">on the market</span> but do not
          <span class="glue"> stay for long.</span> It is necessary to build relationships
          with developers, banks, government organizations,
          <span class="glue">to be in time</span> before others and
          <span class="glue">to arrange</span> everything flawlessly.
          <span class="glue">And that's exactly</span> what we specialize in.
        </div>
      </div>
      <AboutSlider />
    </div>

    <div class="numbers">
      <UIContainerShrunk class="numbers__container">
        <div class="numbers__title">
          Krönung Real Estate in numbers
        </div>
        <div class="numbers__logo">
          <img src="@/assets/images/home/numbers/logo.svg" alt="logo">
        </div>
        <NumbersSliderEN />
      </UIContainerShrunk>
      <div class="numbers__corner">
        <img src="@/assets/images/top_bg_corner.png" alt="corner">
      </div>
    </div>

    <div class="reasons">
      <UIContainerShrunk class="reasons__container">
        <div class="reasons__title">
          The perks of working <span class="glue">with us</span>
        </div>
        <div class="reasons__list" ref="reasonsList">
          <div
            class="reason"
            ref="reason"
            v-for="(reason, i) in reasons"
            :key="reason + i"
          >
            <div class="reason__img">
              <img alt="reason" :src="reason.img">
            </div>
            <div class="reason__text" v-html="reason.text" />
          </div>
        </div>
        <div class="reasons__btn-container" ref="reasonsBtn">
          <UIButton class="reasons__btn" @click="openModal({name: 'contact'})">
            Contact us
          </UIButton>
        </div>
      </UIContainerShrunk>
      <div class="reasons__bg">
        <img src="@/assets/images/home/services/bg.png" alt="bg">
      </div>
      <div class="reasons__bg-mobile">
        <img src="@/assets/images/home/services/bg_mobile.png" alt="bg">
      </div>
    </div>

    <div class="services">
      <UIContainerShrunk class="services__container">
        <div class="services__about">
          <div class="services__about-title">
            Services
          </div>
          <div class="services__about-text">
            The first and most important thing that we are ready to offer
            <span class="glue">is to represent</span> your
            interests on-site. In Dubai, efficient personal communication and networking are key
            to any fruitful business.
            <br><br>
            It is important to note here that according to the
            legislation of Dubai, it is impossible to purchase real estate at any stage
            of readiness without the participation of agents. Such are the rules.
            The commission is paid by the seller. The question is not whether you
            will use the services
            <span class="glue">of an agency,</span> but, rather, who exactly you will
            entrust with the management of your project.
          </div>
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services__estate-container">
        <div class="services__estate">
          <div class="services__estate-title">
            Real Estate Transaction Services
          </div>
          <div class="services__estate-items">
            <div
              class="estate-item"
              v-for="item in estateItems"
              :key="item"
            >
              <div class="estate-item__delimiter" />
              <div class="estate-item__text" v-html="item" />
            </div>
          </div>
        </div>
        <div class="services__estate-corner">
          <img src="@/assets/images/bottom_bg_corner.png" alt="corner">
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services-more__container">
        <div class="services-more__content">
          <div class="services-more">
            <div class="services-more__finance">
              <div class="services-more__title">
                Financial services
              </div>
              <div class="services-more__items">
                <div
                  class="services-more__item"
                  v-for="item in servicesFinance"
                  :key="item"
                  v-html="item"
                />
              </div>
            </div>
            <div class="services-more__legal">
              <div class="services-more__title">
                Legal services
              </div>
              <div class="services-more__items">
                <div
                  class="services-more__item"
                  v-for="item in servicesLegal"
                  :key="item"
                  v-html="item"
                />
              </div>
            </div>
          </div>
        </div>
      </UIContainerShrunk>

      <UIContainerShrunk class="services-tasks__container">
        <div class="services-tasks__corner">
          <img src="@/assets/images/top_bg_corner.png" alt="corner">
        </div>
        <div class="services-tasks">
          <div class="tasks-persons">
            <ServicesSlider />
          </div>

          <div class="tasks-info">
            <div class="tasks-info__content">
              <div class="tasks-info__text">
                Tell us your specifications
                <span class="glue"> and we</span> will offer the best solution
              </div>
              <div class="tasks-info__btn-container">
                <a class="link" href="https://wa.me/79817394249" target="_blank">
                  <UIButton class="tasks-info__btn">
                    Contact us
                  </UIButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </UIContainerShrunk>

      <div class="services__bg-corner">
        <img src="@/assets/images/bottom_bg_corner.png" alt="corner">
      </div>
    </div>

    <div class="comments">
      <UIContainerShrunk class="comments__container">
        <div class="comments__title">Reviews</div>
        <div class="comments__logo">
          <img src="@/assets/images/home/comments/logo.svg" alt="logo">
        </div>

        <CommentsSliderEN />

        <div class="comments__question">
          Want to leave a review about our work?
        </div>
        <div class="comments__btn-container">
          <a class="link" href="https://wa.me/79817394249" target="_blank">
            <UIButton
              class="comments__btn"
            >
              Contact us
            </UIButton>
          </a>
        </div>
      </UIContainerShrunk>
    </div>

    <div class="instagram-widget">
      <div class="elfsight-app-a86b38a9-c3ed-4c46-80e9-8002a20a9755"></div>
    </div>

    <ContactsBlock class="home__contacts" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import validateEmail from '@/utils/validateEmail';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ContactsBlock from '@/components/ContactsBlock.vue';
import navigationInit from '@/mixins/navigationInit';
import ScrollArrow from '@/components/ScrollArrow.vue';
import ServicesSlider from '@/components/views/home/services/ServicesSlider.vue';
import UIContainer from '@/components/UI/UIContainer.vue';
import UIButton from '@/components/UI/UIButton.vue';
import AboutSlider from '@/components/views/home/about/AboutSlider.vue';
import NumbersSliderEN from '@/components/views/home/numbers/NumbersSliderEN.vue';
import UIContainerShrunk from '@/components/UI/UIContainerShrunk.vue';
import CommentsSliderEN from '@/components/views/home/comments/CommentsSliderEN.vue';

gsap.registerPlugin(ScrollTrigger);
let tl;

export default {
  name: 'HomeEN',
  data: () => ({
    errorsTimer: null,
    reasons: [
      {
        img: '/static/images/reasons/1_updated.svg',
        text: 'We speak English for your convenience, <span class="glue">but also Arabic</span> <span class="glue">and Russian,</span> just like locals',
      },
      {
        img: '/static/images/reasons/2.svg',
        text: 'We know the specifics of Dubai, <span class="glue">we are located</span> <span class="glue">in Dubai</span>',
      },
      {
        img: '/static/images/reasons/3.svg',
        text: 'We carry out turnkey transactions, from meeting <span class="glue">at the airport</span> <span class="glue">to registration of ownership</span>',
      },
      {
        img: '/static/images/reasons/4.svg',
        text: 'We value our reputation. <br>People have been working <span class="glue">with us</span> <span class="glue">for decades</span>',
      },
      {
        img: '/static/images/reasons/5.svg',
        text: 'We offer all possible goods <span class="glue">in Business,</span> Premium <span class="glue">and Elite segments</span>',
      },
      {
        img: '/static/images/reasons/6.svg',
        text: 'We have access <span class="glue">to closed</span> <span class="glue">(non-public)</span> offers <span class="glue">from developers</span>',
      },
      {
        img: '/static/images/reasons/7.svg',
        text: 'We offer you <span class="glue">to do</span> everything online. <span class="glue">Some of our</span> clients have never been <span class="glue">to Dubai</span>',
      },
    ],
    estateItems: [
      'We select profitable objects taking into account your requirements and wishes',
      'We present goods online or <span class="glue">in our offices</span> <span class="glue">(Dubai, St. Petersburg)</span>',
      'We accompany and show goods <span class="glue">in Dubai</span> with business class service',
      'We give access to "undisclosed" goods before they\'re officially up <span class="glue">for sale</span>',
      'We select a management company <span class="glue">for renting</span> out real estate',
    ],
    servicesFinance: [
      'We share reliable information about the full cost of ownership',
      'We provide advice on issues related to payment from Russia',
      'We apply for and issue mortgages to non-residents in the UAE',
      'We get all possible (and sometimes a little more) discounts from developers, including interest-free installments',
    ],
    servicesLegal: [
      'We open bank accounts for non-residents of the UAE',
      'We create local legal entities to manage real estate',
      'We help to obtain residency visa (residence permit)',
      'We assist in the relocation of client\'s relatives',
      'We provide legal support for real estate transactions',
    ],
    headerBlocks: [
      'about',
      'contacts',
    ],
    reasonItemsInRow: null,
  }),
  components: {
    ServicesSlider,
    ScrollArrow,
    ContactsBlock,
    CommentsSliderEN,
    UIContainerShrunk,
    NumbersSliderEN,
    AboutSlider,
    UIContainer,
    UIButton,
  },
  // mounted() {
  //   this.initBlocksCoords();
  //   this.initScrollAnimations();
  //
  //   window.addEventListener('scroll', this.onScroll);
  //   window.addEventListener('resize', this.initScrollAnimations);
  //   window.addEventListener('resize', this.initBlocksCoords);
  //
  //   this.onScroll();
  // },
  mounted() {
    this.initScrollAnimations();
  },
  // beforeUnmount() {
  //   window.removeEventListener('scroll', this.onScroll);
  //   window.removeEventListener('resize', this.initScrollAnimations);
  //   window.removeEventListener('resize', this.initBlocksCoords);
  // },
  beforeUnmount() {
    window.removeEventListener('resize', this.initScrollAnimations);
  },
  computed: {
    errors() {
      const { form } = this;
      return {
        name: !form.name,
        email: !form.email || !validateEmail(form.email),
        agreement: !form.agreement,
      };
    },
    hasErrors() {
      return !!Object.values(this.errors).filter(Boolean).length;
    },
  },
  methods: {
    ...mapMutations(['openModal']),
    initScrollAnimations() {
      this.initReasonsAnimation();
    },
    initReasonsAnimation() {
      if (tl) {
        tl.clear();
        tl.kill();
      }
      // if (ScrollTrigger.getById('reasons')) ScrollTrigger.getById('reasons').kill();

      const { reason, reasonsList, reasonsBtn } = this.$refs;

      const containerWidth = gsap.getProperty(reasonsList, 'width');
      const reasonWidth = gsap.getProperty(reason[0], 'width');
      this.reasonItemsInRow = Math.floor(containerWidth / reasonWidth);

      // change scroll animation logic on mobile (animate only once)
      let toggleActions = 'restart pause resume reset';
      const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (viewWidth <= 768) {
        toggleActions = 'play pause resume pause';
      }

      tl = gsap.timeline({
        scrollTrigger: {
          id: 'reasons',
          trigger: reasonsList,
          start: '30% bottom',
          toggleActions,
        },
      });

      const tempReason = [].concat(reason);
      // eslint-disable-next-line no-plusplus
      while (tempReason.length) {
        const slice = [];

        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < this.reasonItemsInRow; k++) {
          slice.push(tempReason.shift());
        }

        tl
          .fromTo(slice, {
            opacity: 0,
            y: 10,
          }, {
            duration: 0.8,
            opacity: 1,
            y: 0,
          }, '>-0.4');
      }

      tl
        .fromTo(reasonsBtn, {
          opacity: 0,
          y: 10,
        }, {
          duration: 0.8,
          opacity: 1,
          y: 0,
        }, '>-0.4');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.preferEmail': function (value) {
      if (value) {
        this.form.preferPhone = false;
        return;
      }
      this.form.preferPhone = true;
    },
    // eslint-disable-next-line func-names
    'form.preferPhone': function (value) {
      if (value) {
        this.form.preferEmail = false;
        return;
      }
      this.form.preferEmail = true;
    },
  },
  mixins: [navigationInit],
};
</script>

<style src="@/styles/views/homeView.scss" lang="scss" scoped/>
