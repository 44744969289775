<template>
  <div class="ui-container-shrunk">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UIContainerShrunk',
};
</script>

<style scoped lang="scss">
.ui-container-shrunk {
  margin: 0 auto;
  width: 100%;
  max-width: 1120px;
  position: relative;
  padding: 0 70px;
  @include media-down($md) {
    padding: 0 40px;
  }
}
</style>
