<template>
  <HomeRU v-if="!isEnglish"/>
  <HomeEN v-else/>
</template>

<script>
import HomeRU from '@/components/views/home/HomeRU.vue';
import HomeEN from '@/components/views/home/HomeEN.vue';
import { mapState } from 'vuex';

export default {
  name: 'HomeView',
  components: { HomeRU, HomeEN },
  computed: {
    ...mapState(['language']),
    isEnglish() {
      return this.language === 'en';
    },
  },
};
</script>

<style lang="scss" scoped/>
