<template>
  <RealEstateEN v-if="isEnglish" />
  <RealEstateRU v-else />
</template>

<script>
import RealEstateEN from '@/components/views/realEstate/RealEstateEN.vue';
import RealEstateRU from '@/components/views/realEstate/RealEstateRU.vue';
import { mapState } from 'vuex';

export default {
  name: 'RealEstateView',
  components: {
    RealEstateRU,
    RealEstateEN,
  },
  computed: {
    ...mapState(['language']),
    isEnglish() {
      return this.language === 'en';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
